import React, { useState } from 'react';
import { navigate } from '@reach/router';
import { Spinner } from '@ui-kitten/components';
import { useTranslation } from 'react-i18next';
import { useAlert } from 'react-alert';
import FirebaseService from '../services/firebase.ts';
import {
  Auth,
  AuthForm,
  AuthContainer,
  PokyGG,
  AuthText,
  AuthFields,
  RedText,
} from '../styles/Shared';
import { PersonIcon } from '../components/Icons.tsx';
import Button from '../components/Button/Button';
import Input from '../components/Input';
import useInput from '../hooks/useInput';

const ForgotPassword = () => {
  const email = useInput('', 'email');
  const { t } = useTranslation(['login', 'errors']);
  const alert = useAlert();
  const [loading, setLoading] = useState(false);

  const onResetPassword = async e => {
    e.preventDefault();
    if (!email.value) {
      email.onSetError(t('errors:inputEmpty'));
      return;
    }
    try {
      setLoading(true);
      await FirebaseService.resetUserPassword(email.value);
      alert.success(
        `${t('PasswordReseted')} ${email.value} ${t('PasswordReseted2')}`
      );
      navigate('/');
    } catch (error) {
      if (
        error.message.includes(
          'There is no user record corresponding to this identifier. The user may have been deleted'
        )
      ) {
        email.onSetError(t('errors:emailNotRegister'));
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Auth>
      <AuthContainer>
        <AuthForm onSubmit={onResetPassword}>
          <PokyGG />
          <AuthText>{t('resetPassword')}</AuthText>
          <AuthFields>
            <Input
              placeholder={t('email')}
              Icon={PersonIcon}
              value={email.value}
              onChange={email.onChange}
              onTouchInput={email.onTouch}
            />
            <RedText>{email.error}</RedText>

            <Button
              background="#3C25FA"
              color="#fff"
              type="submit"
              width="100%"
            >
              {loading ? <Spinner status="basic" /> : t('resetPassword')}
            </Button>
          </AuthFields>
        </AuthForm>
      </AuthContainer>
    </Auth>
  );
};

export default ForgotPassword;
