import { IChallenge } from "../types";
import slugify from 'slugify';

export const getFilenameFromURL = (url: string) => {
  if (url) {
    var m = url.toString().match(/.*\/(.+?)\./);
    if (m && m.length > 1) {
      return m[1];
    }
  }
  return '';
};


export const generateChallengeSlug = (challenge: IChallenge): string =>  `${challenge.id}-${slugify(challenge.title.toLocaleLowerCase(), { replacement: '-', remove: /[.!¡]/g })}`;

export const getIdFromSlug = (url: string) => url.split('-')[0]
