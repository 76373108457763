import * as Localization from 'expo-localization';
import i18n from 'i18n-js';
import { Platform } from 'react-native';
import en from './en.js';
import es from './es.js';

i18n.fallbacks = true;
i18n.defaultLocale = 'es';
i18n.translations = { es, en };
i18n.locale = Platform.OS === 'web' ? 'es' : Localization.locale;

export default i18n;
