import { useState, useEffect, useContext } from 'react';
import { Context } from '../Context';
import FirebaseService from '../services/firebase.ts';

const useSummoner = (id) => {
  const [summoner, setSummoner] = useState({});
  const { setCoins } = useContext(Context);

  useEffect(() => {
    const getSummoner = async () => {
      try {
        const { data } = await FirebaseService.getSummonerByUID(id);
        setSummoner(data);
        setCoins(data.coins);
      } catch (error) {
        console.log(error.message);
      }
    };

    if (id) {
      getSummoner();
    }
  }, [id]);
  return {
    summoner,
    setSummoner,
  };
};

export default useSummoner;
