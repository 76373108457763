import React from 'react';
import { ImageStyle } from 'react-native';
import { Icon, IconElement } from '@ui-kitten/components';
import { Path } from 'react-native-svg';
import SvgIcon from 'react-native-svg-icon';
// #FFAA01
// w16 h13

const generateIcon = (name: string) => (style: ImageStyle): IconElement => (
  <Icon {...style} name={name} />
);

export const CustomIcons = props => <SvgIcon {...props} svgs={customsSvgs} />;

export const customsSvgs = {
  trophy: (
    <Path d="M552 64H448V24c0-13.3-10.7-24-24-24H152c-13.3 0-24 10.7-24 24v40H24C10.7 64 0 74.7 0 88v56c0 35.7 22.5 72.4 61.9 100.7 31.5 22.7 69.8 37.1 110 41.7C203.3 338.5 240 360 240 360v72h-48c-35.3 0-64 20.7-64 56v12c0 6.6 5.4 12 12 12h296c6.6 0 12-5.4 12-12v-12c0-35.3-28.7-56-64-56h-48v-72s36.7-21.5 68.1-73.6c40.3-4.6 78.6-19 110-41.7 39.3-28.3 61.9-65 61.9-100.7V88c0-13.3-10.7-24-24-24zM99.3 192.8C74.9 175.2 64 155.6 64 144v-16h64.2c1 32.6 5.8 61.2 12.8 86.2-15.1-5.2-29.2-12.4-41.7-21.4zM512 144c0 16.1-17.7 36.1-35.3 48.8-12.5 9-26.7 16.2-41.8 21.4 7-25 11.8-53.6 12.8-86.2H512v16z" />
  ),
  coins: (
    <Path d="M0 405.3V448c0 35.3 86 64 192 64s192-28.7 192-64v-42.7C342.7 434.4 267.2 448 192 448S41.3 434.4 0 405.3zM320 128c106 0 192-28.7 192-64S426 0 320 0 128 28.7 128 64s86 64 192 64zM0 300.4V352c0 35.3 86 64 192 64s192-28.7 192-64v-51.6c-41.3 34-116.9 51.6-192 51.6S41.3 334.4 0 300.4zm416 11c57.3-11.1 96-31.7 96-55.4v-42.7c-23.2 16.4-57.3 27.6-96 34.5v63.6zM192 160C86 160 0 195.8 0 240s86 80 192 80 192-35.8 192-80-86-80-192-80zm219.3 56.3c60-10.8 100.7-32 100.7-56.3v-42.7c-35.5 25.1-96.5 38.6-160.7 41.8 29.5 14.3 51.2 33.5 60 57.2z"></Path>
  ),
};

export const TrophyIcon = ({ tintColor, ...style }: ImageStyle): IconElement => (
  <CustomIcons
    {...style}
    fill={tintColor}
    height="28"
    width="24"
    viewBox="0 0 576 512"
    name="trophy"
  />
);

export const CoinsIcon = ({ tintColor = '#FFAA01', ...style }): IconElement => (
  <CustomIcons
    {...style}
    fill={tintColor}
    height="13"
    width="16"
    viewBox="0 0 512 512"
    name="coins"
  />
);

export const EmailIcon = generateIcon('email');

export const EyeIcon = generateIcon('eye');

export const EyeOffIcon = generateIcon('eye-off');

export const PersonIcon = generateIcon('person');

export const CalendarIcon = generateIcon('calendar');

export const BookIcon = generateIcon('file-text');

export const ShareIcon = generateIcon('share');

export const BellIcon = generateIcon('bell-outline');

export const SettingsIcon = generateIcon('settings');

export const LogoutIcon = generateIcon('log-out');

export const BackIcon = generateIcon('arrow-back');

export const StarOutline = (name: string) => (style: ImageStyle): IconElement => (
  <Icon
    style={{ color: 'red', backgroundColor: 'blue' }}
    fill={'red'}
    color={'red'}
    name={'star'}
    backgroundColor="red"
  />
);

export const StarFilled = generateIcon('star');
