import React from 'react';
import { StyleSheet, View, Image } from 'react-native';
import { Text } from '@ui-kitten/components';

interface IProps {
  coins: number
  category: string
}

const PokyCoinsLabel = ({ coins, category = 's1' }: IProps) => {
  return (
    <View style={styles.container}>
      <Text category={category}>{` ${coins}`}</Text>
      <Image
        source={require('../assets/poky-coins-icon.png')}
        style={styles.img}
      />
    </View>
  );
};

export default PokyCoinsLabel;

const styles = StyleSheet.create({
  container: { alignItems: 'center', flexDirection: 'row' },
  img: { width: 12, height: 11, marginLeft: 4 },
});
