import React from 'react';
import styled from 'styled-components';
import { BsArrowLeft, BsChevronRight } from 'react-icons/bs';
import { FiLogOut } from 'react-icons/fi';
import { IoIosSettings, IoIosNotifications, IoMdShare } from 'react-icons/io';
import { useTranslation } from 'react-i18next';
import useCurrentUser from '../hooks/useCurrentUser';
import Button from './Button/Button';
import { SectionName } from '../styles/Shared';
import FirebaseService from '../services/firebase.ts';
import { UserWrapper, UserImg, UserName } from './TopBar/TopBar.style';

const Profile = ({ toggle }) => {
  const { id, name, img } = useCurrentUser();
  const { t } = useTranslation('profile');
  return (
    <ProfileContainer>
      <Backdrop />
      <Content>
        <Button background="transparent" color="#fff" onClick={toggle}>
          <BsArrowLeft
            size={25}
            color="#fff"
            style={{ marginRight: 10 }}
            onClick={toggle}
          />
          <SectionName> {t('profile')}</SectionName>
        </Button>
        {id && (
          <UserWrapper>
            <UserImg src={img} />
            <UserName>{name}</UserName>
          </UserWrapper>
        )}
        <ItemsProfile>
          <a
            href="https://www.facebook.com/Pokygg-110513604002409"
            target="_blank"
            rel="noopener noreferrer"
          >
            <ItemProfile>
              <FlexItem>
                <IoMdShare size={20} color="#fff" style={{ marginRight: 10 }} />
                {t('share')}
              </FlexItem>
              <BsChevronRight size="15" color="#fff" />
            </ItemProfile>
          </a>
          <a
            href="https://poky.gg/terms-of-use-and-conditions/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <ItemProfile>
              <FlexItem>
                <IoIosNotifications
                  size={20}
                  color="#fff"
                  style={{ marginRight: 10 }}
                />
                {t('terms')}
              </FlexItem>
              <BsChevronRight size="15" color="#fff" />
            </ItemProfile>
          </a>
          <a
            href="https://poky.gg/terms-of-use-and-conditions/!#rules"
            target="_blank"
            rel="noopener noreferrer"
          >
            <ItemProfile>
              <FlexItem>
                <IoIosSettings
                  size={20}
                  color="#fff"
                  style={{ marginRight: 10 }}
                />
                {t('rules')}
              </FlexItem>
              <BsChevronRight size="15" color="#fff" />
            </ItemProfile>
          </a>

          {id && (
            <ItemProfile onClick={() => FirebaseService.signOut()}>
              <FlexItem>
                <FiLogOut size={20} color="#fff" style={{ marginRight: 10 }} />
                {t('logout')}
              </FlexItem>
              <BsChevronRight size="15" color="#fff" />
            </ItemProfile>
          )}
        </ItemsProfile>
      </Content>
    </ProfileContainer>
  );
};

const ProfileContainer = styled.div`
  width: 100%;
  height: 100vh;
  position: absolute;
  left: 70px;
  top: 0;
`;

const Backdrop = styled.div`
  width: 100%;
  position: fixed;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: -1;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.2);
`;

const ItemsProfile = styled.div`
  width: 100%;
  margin-top: 3rem;
`;

const ItemProfile = styled.div`
  width: 100%;
  padding: 2rem 0;
  cursor: pointer;
  border-bottom: 1px solid #27314d;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const FlexItem = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.3rem;
  font-weight: bold;
  color: #fff;
`;

const Content = styled.div`
  width: 400px;
  height: 100vh;
  position: absolute;
  z-index: 2;
  background: #131a2f;
  padding: 3rem 1rem;

  animation: slide-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

  @keyframes slide-right {
    0% {
      -webkit-transform: translateX(0);
      transform: translateX(-20px);
    }
    100% {
      transform: translateX(0);
    }
  }
`;

export default Profile;
