import React from 'react';
import styled from 'styled-components';
import badge from '../../assets/Marcos/bronce.png';
import { Border } from './Border';

const BronceBorder = ({ children }) => (
  <Border color="#cd7f32">
    <CenterBadge />
    {children}
  </Border>
);

export const CenterBadge = styled.div`
  height: 30px;
  width: 80px;
  position: absolute;
  right: 0;
  left: 0;
  margin: auto;
  top: -18px;
  background: url(${badge});
  background-size: cover;
`;

export default BronceBorder;
