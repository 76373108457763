import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import Challenges from '../screens/challenge/Challenges';
import ChallengeDetails from '../screens/challenge/ChallengeDetails';

const ChallengesStack = createStackNavigator();

function ChallengesStackScreens() {
  return (
    <ChallengesStack.Navigator headerMode="none">
      <ChallengesStack.Screen name="Challenges" component={Challenges} />
      <ChallengesStack.Screen name="ChallengeDetails" component={ChallengeDetails} />
    </ChallengesStack.Navigator>
  );
}

export default ChallengesStackScreens;
