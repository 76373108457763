import { navigate } from '@reach/router';
import React, { useState, createContext, useEffect } from 'react';

export const Context = createContext();

const Provider = ({ children }) => {
  const [coins, setCoins] = useState(0);

  const value = {
    coins,
    setCoins,
  };

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export default {
  Provider,
  Consumer: Context.Consumer,
};
