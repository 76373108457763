import styled from 'styled-components';

import React from 'react';
import GoldBorder from './GoldBorder';
import SilverBorder from './SilverBorder';
import BronceBorder from './BronceBorder';

const BorderComp = ({ position, children }) => {
  if (position === 1) {
    return <GoldBorder>{children}</GoldBorder>;
  }
  if (position === 2) {
    return <SilverBorder>{children}</SilverBorder>;
  }
  if (position === 3) {
    return <BronceBorder>{children}</BronceBorder>;
  }

  return children;
};

export const Border = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  border-radius: 5px;
  border: 1px solid ${props => props.color};
  position: relative;
  margin-bottom: 2rem;
`;

export default BorderComp;
