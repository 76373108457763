import React, { useContext, useEffect, useState } from 'react';
import { navigate } from '@reach/router';
import styled from 'styled-components';
import { Spinner, Text } from '@ui-kitten/components';
import { Platform } from 'react-native';
import { useTranslation } from 'react-i18next';
import Layout from '../components/Layout/Layout';
import useGetCurrentChallenges from '../hooks/useGetCurrentChallenges';
import { WrapperPageContent, SectionName, WrapperAds } from '../styles/Shared';
import useGetFutureChallenges from '../hooks/useGetFutureChallenge';
import FeatureCard from '../components/FeatureCard';
import ChallengeCard from '../components/ChallengeCard';
import useCurrentUser from '../hooks/useCurrentUser';
import useSummoner from '../hooks/useSummoner';
import AdsBanner from '../components/AdsBanner.web.tsx';
import { generateChallengeSlug } from '../utils/index';
import ModalWinner from '../components/ModalWinner.tsx';
import { Button } from 'react-native';
import FirebaseService from '../services/firebase.ts';
import { Context } from '../Context';

const HistoryCardContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0 10px;
`;
const Challenge = () => {
  const {
    nextChallenges,
    loading: loadingUpcomingChallenges,
  } = useGetFutureChallenges();
  const { challenges, loading: loadingChallenges } = useGetCurrentChallenges();
  const { id } = useCurrentUser();
  const { summoner, setSummoner } = useSummoner(id);
  const { setCoins } = useContext(Context);
  const { t } = useTranslation(['challenges']);
  const [open, setOpen] = React.useState(false);
  const [pendingTransaction, setPendingTransaction] = useState([]);

  useEffect(() => {
    if (!summoner) {
      navigate('/create-summoner');
    }
  }, [summoner]);

  useEffect(() => {
    const getPendingTransactions = async () => {
      try {
        const { data } = await FirebaseService.getPendingTransactions();
        if (data.pendingTransactions.length > 0) {
          setOpen(true);
        }

        setPendingTransaction(data.pendingTransactions);
      } catch (error) {
        console.log(error);
      }
    };
    if (summoner) {
      getPendingTransactions();
    }
  }, [summoner]);

  const onUpdateCoins = (summoner) => {
    setCoins(summoner.coins);
    setOpen(false);
  };

  const onClickChallenge = (challenge) =>
    navigate(`/challenge/${generateChallengeSlug(challenge)}`);

  return (
    <>
      <ModalWinner
        visible={open}
        toggleModal={() => setOpen(!open)}
        coins={pendingTransaction[0]?.amount}
        transactionId={pendingTransaction[0]?.id}
        onUpdateCoins={onUpdateCoins}
      />
      <Layout>
        <WrapperPageContent>
          <SectionName>{t('currentChallenge')}</SectionName>

          {loadingChallenges ? (
            <Spinner status="basic" />
          ) : (
            <HistoryCardContainer>
              {challenges.map((challenge) => (
                <FeatureCard
                  key={challenge.id}
                  {...challenge}
                  onClick={() => onClickChallenge(challenge)}
                />
              ))}
            </HistoryCardContainer>
          )}
          <WrapperAds>
            {Platform.OS === 'web' ? (
              <AdsBanner inArticle marginBottom={20} />
            ) : null}
          </WrapperAds>
          <SectionName>{t('futureChanllenges')}</SectionName>
          {loadingUpcomingChallenges ? (
            <Spinner status="basic" />
          ) : (
            <HistoryCardContainer>
              {nextChallenges.length === 0 ? (
                <Text category="s1" style={{ color: '#fff' }}>
                  {t('notChallenges')}
                </Text>
              ) : (
                nextChallenges.map((nextChallenge) => (
                  <ChallengeCard
                    {...nextChallenge}
                    key={nextChallenge.id}
                    onClick={() => onClickChallenge(nextChallenge)}
                  />
                ))
              )}
            </HistoryCardContainer>
          )}
        </WrapperPageContent>
      </Layout>
    </>
  );
};

export default Challenge;
