import * as Localization from 'expo-localization';
import { createContext } from 'react';
import * as firebase from 'firebase';
// Required for side-effects
import 'firebase/functions';
import 'firebase/firestore';
import { LeaderBoard } from '../types';

const firebaseConfig = {
  apiKey: 'AIzaSyBx6IxlLlhtf-HxHoJp5pdiAtKURY6XEtE',
  authDomain: 'byyuto-lol-project.firebaseapp.com',
  databaseURL: 'https://byyuto-lol-project.firebaseio.com',
  projectId: 'byyuto-lol-project',
  storageBucket: 'byyuto-lol-project.appspot.com',
  messagingSenderId: '511003466499',
  appId: '1:511003466499:web:6de899890d6da58f332736',
  measurementId: 'G-EGQDNQ3Y7K',
};

export const AuthContext = createContext(null);

class FirebaseService {
  private static instance: FirebaseService;

  private constructor(config = firebaseConfig) {
    if (!firebase.apps.length) {
      firebase.initializeApp(config);
    }
    //firebase.functions().useFunctionsEmulator('http://localhost:5001');
  }

  public static init(): void {
    if (!FirebaseService.instance) {
      FirebaseService.instance = new FirebaseService();
    }
  }

  // MARK: User methods

  public static signInWithEmailAndPassword(
    email: string,
    password: string
  ): Promise<firebase.auth.UserCredential> {
    return firebase.auth().signInWithEmailAndPassword(email, password);
  }

  public static createUserWithEmailAndPassword(
    email: string,
    password: string
  ): Promise<firebase.auth.UserCredential> {
    return firebase.auth().createUserWithEmailAndPassword(email, password);
  }

  public static resetUserPassword = (email: string) => {
    return firebase.auth().sendPasswordResetEmail(email);
  };

  public static signOut(): Promise<void> {
    return firebase.auth().signOut();
  }

  public static onAuthStateChanged(callback): firebase.Unsubscribe {
    return firebase.auth().onAuthStateChanged(callback);
  }

  public static getCurrentUser(): firebase.User {
    return firebase.auth().currentUser;
  }

  public static refreshUser(): Promise<void> {
    return firebase.auth().currentUser.reload();
  }

  public static createSummoner = (summonerName: string, region: string) => {
    return firebase.functions().httpsCallable('createSummoner')({
      name: summonerName,
      region: region.toUpperCase(),
    });
  };

  public static getSummonerByUID = (uid: string) => {
    return firebase.functions().httpsCallable('getSummonerByUID')({
      uid: uid,
    });
  };

  // MARK: Challenges methods
  public static getLeaderboard = async (
    challengeId: string
  ): Promise<LeaderBoard> => {
    const response = await firebase
      .functions()
      .httpsCallable('getChallengeLeaderboard')({
      challengeId,
    });
    return response?.data;
  };

  public static subscribeToChallenge = (challengeId: string) => {
    return firebase.functions().httpsCallable('subscribeToChallenge')({
      challengeId,
    });
  };

  public static getCurrentChallenges = async () => {
    return firebase.functions().httpsCallable('getCurrentChallenges')();
  };

  public static getNextChallenges = async () => {
    return firebase.functions().httpsCallable('getNextChallenges')();
  };

  public static getHistoryChallenges = async () => {
    return firebase.functions().httpsCallable('getHistoryChallenges')();
  };

  public static calculateChallengeLeaderboard = async (challengeId: string) => {
    return firebase.functions().httpsCallable('calculateChallengeLeaderboard')({
      challengeId,
    });
  };

  public static updateDeviceToken = async (
    deviceToken: string,
    locale = Localization.locale
  ) => {
    console.log('locale and devicetoken');
    console.log({ deviceToken, locale });
    return firebase.functions().httpsCallable('updateDeviceToken')({
      deviceToken,
      locale,
    });
  };

  public static getChallenge = async (challengeId: string) => {
    return firebase.functions().httpsCallable('getChallenge')({
      challengeId,
    });
  };

  public static claimPrice = async (transactionId: string) => {
    return firebase.functions().httpsCallable('claimPrize')({
      transactionId,
    });
  };

  public static getPendingTransactions = async () => {
    return firebase.functions().httpsCallable('getPendingTransactions')({});
  };
}

export default FirebaseService;
