import React from 'react';
import AdSense from 'react-adsense';

const AdsBanner = ({ marginBottom = 0 }) =>
  <AdSense.Google
    client="ca-pub-4141016175538594"
    slot="4783636477"
    style={{ display: 'block', marginBottom }}
    layout='in-article'
    format='fluid'
  />
  ;

export default AdsBanner;
