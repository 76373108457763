import React, { useEffect, useState } from "react";
import { StyleSheet, View, Image } from "react-native";
import { Button, Input, Text } from "@ui-kitten/components";
import { ImageOverlay } from "./extra/image-overlay.component";
import { PersonIcon, BackIcon } from "../../components/Icons";
import FirebaseService from "../../services/firebase";
import { TouchableOpacity } from "react-native-gesture-handler";
import { useSafeArea } from "react-native-safe-area-context";
import i18n from "../../i18n";

const ResetPassword = ({ navigation }) => {
  const [email, setEmail] = React.useState<string>("");
  const [emailError, setEmailError] = useState("");
  const [loading, setLoading] = React.useState<boolean>(false);
  const insets = useSafeArea();

  const onResetPassword = async (): Promise<void> => {
    if (!email) {
      setEmailError(i18n.t("errors.inputEmpty"));
      return;
    }
    try {
      setLoading(true);
      await FirebaseService.resetUserPassword(email.trim());
      alert(
        `${i18n.t("login.PasswordReseted")} ${email} ${i18n.t(
          "login.PasswordReseted2"
        )}`
      );
      navigation.navigate({ name: "Home" });
    } catch (error) {
      if (
        error.message.includes(
          "There is no user record corresponding to this identifier. The user may have been deleted"
        )
      ) {
        setEmailError(i18n.t("errors.emailNotRegister"));
      }
    } finally {
      setLoading(false);
    }
  };

  const onRenderContent = () => (
    <>
      <View
        style={{
          marginTop: 10 + insets.top,
          marginLeft: 10 + insets.left,
          alignSelf: "flex-start",
          width: 45,
        }}
      >
        <TouchableOpacity onPress={() => navigation.goBack()}>
          <BackIcon height={30} width={30} tintColor="white" />
        </TouchableOpacity>
      </View>
      <View style={styles.headerContainer}>
        <Image
          style={{
            resizeMode: "contain",
            height: 100,
            maxWidth: "80%",
            minWidth: 318,
          }}
          source={require("../../assets/logo.png")}
        />
        <Text style={styles.signInLabel} category="s1" status="control">
          {i18n.t("login.resetPassword")}
        </Text>
      </View>

      <View style={styles.formContainer}>
        <Input
          status="control"
          placeholder={i18n.t("login.email")}
          autoCapitalize="none"
          icon={PersonIcon}
          caption={emailError}
          captionStyle={{ color: "red" }}
          value={email}
          onChangeText={(text) => {
            setEmail(text);
            setEmailError("");
          }}
        />
      </View>
      <Button
        style={styles.signInButton}
        status="control"
        size="large"
        onPress={onResetPassword}
      >
        {loading ? i18n.t("summoner.loading") : i18n.t("login.resetPassword")}
      </Button>
    </>
  );

  return (
    <ImageOverlay
      style={styles.container}
      source={require("./assets/image-background.jpg")}
    >
      {onRenderContent()}
    </ImageOverlay>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  headerContainer: {
    justifyContent: "center",
    alignItems: "center",
    minHeight: 216,
  },
  formContainer: {
    flex: 1,
    marginTop: 32,
    paddingHorizontal: 16,
  },
  signInLabel: {
    marginTop: 16,
  },
  signInButton: {
    marginHorizontal: 16,
    marginBottom: 30,
  },
  signUpButton: {
    marginVertical: 12,
    marginHorizontal: 16,
  },
  agreementCopy: {
    marginVertical: 12,
    marginHorizontal: 30,
  },
  forgotPasswordContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  passwordInput: {
    marginTop: 16,
  },
  forgotPasswordButton: {
    paddingHorizontal: 0,
  },
});

export default ResetPassword;
