import { useState } from 'react';
import FirebaseService from '../services/firebase.ts';

const useCurrentUser = () => {
  const [user, setUser] = useState(FirebaseService.getCurrentUser());
  return {
    id: user?.uid,
    name: user?.displayName,
    email: user?.email,
    img: user?.photoURL,
    setUser,
  };
};

export default useCurrentUser;
