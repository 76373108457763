import React from 'react'
import { View, Text, Image } from 'react-native'
import { withStyles } from '@ui-kitten/components';
import PokyCoinsLabel from './PokyCoinsLabel';

function JoinSection(props) {
  if (!props.coins) {
    return (
      <View {...props} style={props.themedStyle.container}>
        <Text category="c2" style={props.themedStyle.text}>Join Free</Text>
      </View>
    );
  }
  return (
    <View {...props} style={props.themedStyle.container}>
      <View style={props.themedStyle.crownWrapper}>
        <Image
          style={props.themedStyle.crownImg}
          source={require('../assets/crown.png')}
        />
      </View>
      <Text category="c2" style={props.themedStyle.text}>Premium </Text>
      <PokyCoinsLabel category="c2" coins={props.coins} />
    </View>
  );
}

const themedStyles = theme => ({
  container: {
    backgroundColor: theme['color-primary-500'],
    height: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 15,
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    minWidth: '30%',
    marginRight: 12
  },
  crownWrapper: {
    height: 18,
    width: 18,
    borderRadius: 9,
    marginRight: 5,
    backgroundColor: theme['color-primary-700'],
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0
  },
  crownImg: {
    width: 7,
    height: 7,
    marginLeft: 1,
  },
  text: {
    color: '#FFFFFF'
  }
})




export default withStyles(JoinSection, themedStyles);
