import React from "react";
import { SafeAreaView } from "react-native";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { BottomNavigation, BottomNavigationTab } from "@ui-kitten/components";
import { PersonIcon, BookIcon, TrophyIcon } from "../components/Icons";
import History from "../screens/challenge/History";
import Profile from "../screens/auth/Profile";
import ChallengesStackScreens from "./ChallengesStack";
import i18n from "../i18n";
import ProfileStackScreens from "./ProfileStack";

const BottomTab = createBottomTabNavigator();

const BottomTabBar = ({ navigation, state }) => {
  const onSelect = (index) => {
    navigation.navigate(state.routeNames[index]);
  };

  return (
    <SafeAreaView style={{ backgroundColor: "#101426", paddingTop: 0 }}>
      <BottomNavigation
        selectedIndex={state.index}
        onSelect={onSelect}
        style={{ backgroundColor: "#101426" }}
      >
        <BottomNavigationTab
          title={i18n.t("nav.challenge")}
          icon={TrophyIcon}
        />
        <BottomNavigationTab title={i18n.t("nav.history")} icon={BookIcon} />
        <BottomNavigationTab title={i18n.t("nav.profile")} icon={PersonIcon} />
      </BottomNavigation>
    </SafeAreaView>
  );
};

const TabNavigator = () => (
  <BottomTab.Navigator tabBar={(props) => <BottomTabBar {...props} />}>
    <BottomTab.Screen
      name="ChallengesStack"
      component={ChallengesStackScreens}
    />
    <BottomTab.Screen name="History" component={History} />
    <BottomTab.Screen name="ProfileStack" component={ProfileStackScreens} />
  </BottomTab.Navigator>
);

export default TabNavigator;
