import React from 'react';
import { CheckboxContainer } from './CheckBox.style';

const Checkbox = ({ id, children, onToggle, checked }) => (
  <CheckboxContainer>
    <label className="checkbox bounce" htmlFor={id}>
      <input type="checkbox" id={id} checked={checked} onChange={onToggle} />
      <svg viewBox="0 0 21 21">
        <polyline points="5 10.75 8.5 14.25 16 6" />
      </svg>
    </label>
    <span>{children}</span>
  </CheckboxContainer>
);

export default Checkbox;
