import React from "react";
import styled from "styled-components";
import { Image, View } from "react-native";
import get from "lodash/get";
import badge from "../../assets/Marcos/Oro-1.png";
import useComponentSize from "../../hooks/useComponentSize";
import goldLeft from "../../assets/Marcos/Oro-2.png";
import goldRight from "../../assets/Marcos/Oro-3.png";
import { BorderWrapper } from "./Border";

const Gold = ({ children, activeUser }) => {
  const [size, onLayout] = useComponentSize();
  return (
    <BorderWrapper onLayout={onLayout} color="#D4AF37" activeUser={activeUser}>
      <Left source={goldLeft} style={{ resizeMode: "contain" }} />
      <Badge
        source={badge}
        style={{ position: "absolute", left: get(size, "width", 1) / 2 - 25 }}
      />
      <Right source={goldRight} style={{ resizeMode: "contain" }} />
      {children}
    </BorderWrapper>
  );
};

const Badge = styled(Image)`
  position: absolute;
  width: 50px;
  height: 30px;
  top: -15px;
  left: 0;
`;

const Left = styled(Image)`
  position: absolute;
  width: 50px;
  height: 30px;
  top: -5px;
  left: -20px;
`;

const Right = styled(Image)`
  position: absolute;
  width: 50px;
  height: 30px;
  top: -5px;
  right: -20px;
`;

export default Gold;
