import React, { useState, useEffect, useContext } from "react";
import { Layout, Text } from "@ui-kitten/components";
import SummonerInfoHeader from "../../components/SummonerInfoHeader";
import { Menu } from "@ui-kitten/components";
import {
  ShareIcon,
  StarFilled,
  LogoutIcon,
  BookIcon,
} from "../../components/Icons";
import { Feather } from "@expo/vector-icons";
import alert from "../../services/alert";
import openURL from "../../services/openURL";
import { Platform } from "react-native";
import Constants from "../../utils/constants";
import i18n from "../../i18n";
import FirebaseService, { AuthContext } from "../../services/firebase";
const version = Constants.manifest.version;

const streamIcon = () => <Feather name="cast" size={15} color="#8f9bb3" />;

const MenuWithIconsShowcase = ({ goToStream }) => {
  const { user } = useContext(AuthContext);
  const [data, setData] = useState([
    // {
    //   title: 'Get Free Coins (Comming soon)',
    //   icon: CoinsIcon,
    // },
    {
      title: i18n.t("profile.share"),
      icon: ShareIcon,
      action: "share",
    },
    {
      title: i18n.t("profile.rate"),
      icon: StarFilled,
      action: "rate",
    },
    {
      title: i18n.t("profile.terms"),
      icon: BookIcon,
      action: "Terms",
    },
    {
      title: i18n.t("profile.rules"),
      icon: BookIcon,
      action: "Rules",
    },
    // {
    //   title: 'Notifications',
    //   icon: BellIcon,
    //   action: "notifications"
    // },
    // {
    //   title: 'Settings',
    //   icon: SettingsIcon,
    //   action: "settings"
    // },
    // {
    //   title: 'Get Free Coins (Comming soon)',
    //   icon: CoinsIcon,
    // },
  ]);

  useEffect(() => {
    if (user) {
      setData([
        {
          title: i18n.t("profile.stream"),
          icon: streamIcon,
          action: "openStream",
        },
        ...data,
        {
          title: i18n.t("profile.logout"),
          icon: LogoutIcon,
          action: "logout",
        },
      ]);
    }
  }, [user]);

  const onSelect = (selectedIndex) => {
    const selectedOption = data[selectedIndex];
    switch (selectedOption.action) {
      case "logout":
        setData(data.slice(0, -1));
        FirebaseService.signOut();
        break;
      case "share":
        openURL(
          Platform.select({
            ios: "https://www.facebook.com/Pokygg-110513604002409",
            android: "https://www.facebook.com/Pokygg-110513604002409",
            default: "https://www.facebook.com/Pokygg-110513604002409",
          })
        );
        break;
      case "rate":
        openURL(
          Platform.select({
            ios: "https://apps.apple.com/us/app/id1513184212",
            android:
              "https://play.google.com/store/apps/details?id=com.byyuto.pokygg",
            default: "https://www.facebook.com/Pokygg-110513604002409",
          })
        );
        break;
      case "Terms":
        openURL(
          Platform.select({
            ios: "https://poky.gg/terms-of-use-and-conditions/",
            android: "https://poky.gg/terms-of-use-and-conditions/",
            default: "https://poky.gg/terms-of-use-and-conditions/",
          })
        );
        break;
      case "Rules":
        openURL(
          Platform.select({
            ios: "https://poky.gg/terms-of-use-and-conditions/!#rules",
            android: "https://poky.gg/terms-of-use-and-conditions/!#rules",
            default: "https://poky.gg/terms-of-use-and-conditions/!#rules",
          })
        );
        break;
      case "openStream":
        goToStream();
        break;
      default:
        alert("This option will be available soon");
        break;
    }
  };

  return <Menu data={data} onSelect={onSelect} />;
};

const Profile = ({ navigation }) => {
  return (
    <>
      <SummonerInfoHeader />
      <Layout style={{ flex: 1 }}>
        <MenuWithIconsShowcase
          goToStream={() => navigation.navigate("createChannel")}
        />
        <Text
          style={{
            paddingHorizontal: 15,
            paddingBottom: 15,
            flexDirection: "row",
            fontSize: 10,
            alignSelf: "center",
          }}
        >
          App version {version}
        </Text>
      </Layout>
    </>
  );
};

export default Profile;
