import React, { useContext } from "react";
import { createStackNavigator } from "@react-navigation/stack";
import ChallengeDetails from "../screens/challenge/ChallengeDetails";
import Login from "../screens/auth/Login";
import Signup from "../screens/auth/Signup";
import SetSummoner from "../screens/auth/SetSummoner";
import { AuthContext } from "../services/firebase";
import TabNavigator from "./TabNavigator";
import ResetPassword from "../screens/auth/ResetPassword";

const AuthStack = createStackNavigator();

// Gets the current screen from navigation state
const getActiveRouteName = (state) => {
  const route = state.routes[state.index];

  if (route.state) {
    // Dive into nested navigators
    return getActiveRouteName(route.state);
  }

  return route.name;
};

function AuthStackScreens() {
  const { user, summoner } = useContext(AuthContext);
  return (
    <AuthStack.Navigator headerMode="none">
      {user && !summoner ? (
        <AuthStack.Screen name="SetSummoner" component={SetSummoner} />
      ) : (
        <AuthStack.Screen name="Home" component={TabNavigator} />
      )}
      {!user ? (
        <>
          <AuthStack.Screen name="Login" component={Login} />
          <AuthStack.Screen name="SignUp" component={Signup} />
          <AuthStack.Screen name="ResetPassword" component={ResetPassword} />
        </>
      ) : null}
    </AuthStack.Navigator>
  );
}

export default AuthStackScreens;
