import {useState} from 'react';

const useComponentSize = () => {
    const [size, setSize] = useState({});

    const onLayout = event => {
      const { width, height } = event.nativeEvent.layout;
      setSize({ width, height});
    };
  
    return [size, onLayout];
  };

export default useComponentSize;