import React, { useContext } from 'react';
import styled from 'styled-components';
import { navigate } from '@reach/router';
import { Spinner } from '@ui-kitten/components';
import { Platform } from 'react-native';
import { useTranslation } from 'react-i18next';
import { WrapperPageContent, SectionName, WrapperAds } from '../styles/Shared';
import HistoryCard from '../screens/challenge/extra/HistoryCard.tsx';
import useGetPastChallenges from '../hooks/useGetPastChallenge';
import Layout from '../components/Layout/Layout';
import AdsBanner from '../components/AdsBanner.web.tsx';
import { generateChallengeSlug } from '../utils/index';
import ModalWinner from '../components/ModalWinner.tsx';
import FirebaseService from '../services/firebase.ts';
import { Context } from '../Context';

const HistoryCardContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0 10px;
`;

const PastChallenge = () => {
  const {
    pastChallenges,
    loading,
    setLoading,
    setPastChalleges,
  } = useGetPastChallenges();
  const onClickChallenge = (challenge) =>
    navigate(`/challenge/${generateChallengeSlug(challenge)}`);
  const { t } = useTranslation(['history']);
  const [open, setOpen] = React.useState(false);
  const [reward, setReward] = React.useState(null);
  const { setCoins } = useContext(Context);

  const getChallenges = async () => {
    try {
      setLoading(true);
      const { data } = await FirebaseService.getHistoryChallenges();
      setPastChalleges(data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const onPressReward = (userPrice) => {
    setReward(userPrice);
    setOpen(true);
  };

  const onUpdateCoins = (summoner) => {
    setCoins(summoner.coins);
    setOpen(false);
    getChallenges();
  };

  return (
    <Layout>
      <ModalWinner
        visible={open}
        toggleModal={() => setOpen(!open)}
        coins={reward?.amount}
        transactionId={reward?.id}
        onUpdateCoins={onUpdateCoins}
      />
      <WrapperPageContent>
        <SectionName style={{ gridColumn: 'span 2' }}>
          {t('pastChallenge')}
        </SectionName>

        <HistoryCardContainer>
          {loading ? (
            <Spinner status="basic" />
          ) : (
            pastChallenges.map((pastChallenge, i) => (
              <HistoryCard
                {...pastChallenge}
                key={i}
                onPressReward={onPressReward}
                onPress={() => onClickChallenge(pastChallenge)}
              />
            ))
          )}
        </HistoryCardContainer>
        <WrapperAds>
          <AdsBanner inArticle marginBottom={20} />
        </WrapperAds>
      </WrapperPageContent>
    </Layout>
  );
};

export default PastChallenge;
