import React from 'react';
import styled from 'styled-components';
import { LogoWeb } from './Nav/Nav.style';

const Loading = () => (
  <LoadingContainer>
    <Wrapper>
      <LogoWeb />
      <Text>Loading ...</Text>
    </Wrapper>
  </LoadingContainer>
);

const LoadingContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div`
  width: 100px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Text = styled.div`
  font-size: 1.6rem;
  color: #fff;
  text-align: center;
  margin-top: 1rem;
`;

export default Loading;
