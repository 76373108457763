import styled from 'styled-components';
import { Link } from '@reach/router';
import logo from '../../assets/logoWeb.png';

export const NavContainer = styled.nav`
  width: 70px;
  background: rgb(15, 20, 37);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 10px 0;
  color: #fff;
  grid-area: nav;
  position: fixed;
  height: 100vh;
  z-index: 1;
`;

export const NavItems = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const NavItem = styled(Link)`
  width: 50px;
  display: flex;
  padding: 10px 5px;
  border-radius: 5px;
  cursor: pointer;
  color: #2e3a59;
  font-size: 1.2rem;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 3rem;
  transition: ease all 0.3s;
  &[aria-current] {
    color: #3d25fb;
  }
  :hover {
    color: #3d25fb;
  }
`;

export const NavItemButton = styled.div`
  width: 50px;
  display: flex;
  flex-direction: column;
  font-size: 1.2rem;
  padding: 10px 5px;
  border-radius: 5px;
  cursor: pointer;
  color: ${props => (props.active ? '#3d25fb' : '#2e3a59')};
  align-items: center;
  justify-content: center;
  margin-bottom: 3rem;
  transition: ease all 0.3s;
  :hover {
    color: #3d25fb;
  }
`;

export const LogoWeb = styled.div`
  width: 50px;
  height: 50px;
  background: url(${logo});
  background-size: cover;
  background-position: center;
`;
