import styled from 'styled-components';
import { Link } from '@reach/router';

export const TopBarContainer = styled.div`
  height: 80px;
  background: rgb(15, 20, 37);
  padding: 10px 20px;
  display: flex;
  align-items: center;
  color: #fff;
  justify-content: space-between;
`;

export const Links = styled.div`
  display: flex;
  align-items: center;
`;

export const OutFillLInk = styled(Link)`
  color: #3c25fa;
  border: 1px solid #3c25fa;
  border-radius: 5px;
  padding: 1rem;
  transition: ease all 0.3s;
  background: transparent;
  font-weight: bold;
  min-width: 100px;
  text-align: center;
  margin-right: 1rem;
  font-size: 1.4rem;
  :hover {
    background: #3c25fa;
    color: #fff;
  }
`;

export const FillLink = styled(Link)`
  background: #3c25fa;
  color: #fff;
  min-width: 100px;
  text-align: center;
  padding: 1rem;
  font-size: 1.4rem;
  border-radius: 5px;
  font-weight: bold;
`;

export const UserWrapper = styled.div`
  width: 300px;
  display: flex;
  align-items: center;
  position: relative;
`;

export const UserImg = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 1rem;
  object-fit: cover;
  object-position: center;
`;

export const UserName = styled.div`
  font-size: 1.3rem;
  font-weight: bold;
  text-transform: uppercase;
`;

export const UserLevel = styled.span`
  position: absolute;
  background: #ff7607;
  border-radius: 50%;
  color: #fff;
  font-size: 1rem;
  bottom: -5px;
  left: 30px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SelectInput = styled.select`
  background: #3c25fa;
  color: #fff;
  min-width: 100px;
  text-align: center;
  border: 0;
  outline: 0;
  padding: 1rem;
  font-size: 1.4rem;
  border-radius: 5px;
  & option {
    background: rgb(15, 20, 37);
    border: 1px solid rgb(15, 20, 37);
  }
`;
