import React, { useState } from 'react';
import { Image, Platform, StyleSheet, TouchableOpacity } from 'react-native';
import { Button, Layout, Modal, Text } from '@ui-kitten/components';
import poky from '../assets/poky.png';
import { AntDesign } from '@expo/vector-icons';
import i18n from '../i18n/';
import { useTranslation } from 'react-i18next';
import FirebaseService from '../services/firebase';
import { Spinner } from '@ui-kitten/components';

const ModalWinner = ({
  toggleModal,
  visible,
  coins,
  transactionId,
  onUpdateCoins,
}) => {
  const { t } = useTranslation(['popupWinner']);
  const [loading, setLoading] = useState(false);
  const onClaimReward = async () => {
    try {
      setLoading(true);
      const { data } = await FirebaseService.claimPrice(transactionId);
      onUpdateCoins(data.summoner);
      setLoading(false);
    } catch (error) {
      setLoading(false);

      console.log(error);
    }
  };

  const renderModalElement = () => (
    <Layout level="3" style={styles.modalContainer}>
      <Image source={poky} style={styles.modalImg} />
      <Text style={styles.title}>
        {Platform.OS === 'web' ? t('congrats') : i18n.t('popupWinner.congrats')}
      </Text>
      <Text style={styles.description}>
        {' '}
        {Platform.OS === 'web'
          ? t('description')
          : i18n.t('popupWinner.description')}
      </Text>
      <Text style={{ marginTop: 20, fontSize: 13 }}>
        {' '}
        {Platform.OS === 'web' ? t('won') : i18n.t('won')}{' '}
        <Text style={{ fontWeight: '500' }}>{coins} PokyCoins</Text>
      </Text>
      <TouchableOpacity style={styles.button} onPress={onClaimReward}>
        <Text
          style={{
            color: '#3C25FA',
            textTransform: 'uppercase',
            fontSize: 14,
            fontWeight: '500',
          }}
        >
          {loading ? (
            <Spinner />
          ) : Platform.OS === 'web' ? (
            t('claim')
          ) : (
            i18n.t('popupWinner.claim')
          )}{' '}
        </Text>
      </TouchableOpacity>
      <Text style={styles.bottomText}>
        {' '}
        {Platform.OS === 'web'
          ? t('bottomText')
          : i18n.t('popupWinner.bottomText')}
      </Text>
      <AntDesign
        name="close"
        size={12}
        color="#fff"
        style={Platform.OS === 'web' ? styles.closeIconWeb : styles.closeIcon}
        onPress={toggleModal}
      />
    </Layout>
  );

  if (visible) {
    return (
      <Layout style={styles.container}>
        <Modal
          backdropStyle={styles.backdrop}
          onBackdropPress={toggleModal}
          visible={visible}
        >
          {renderModalElement()}
        </Modal>
      </Layout>
    );
  }

  return <></>;
};

const styles = StyleSheet.create({
  container: {
    minHeight: 256,
    padding: 16,
    position: 'absolute',
  },
  modalContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    borderRadius: 5,
    minWidth: 320,
    maxWidth: 400,
    padding: 16,
    paddingTop: 40,
    backgroundColor: '#3C25FA',
  },
  title: {
    fontSize: 16,
    fontWeight: '600',
    textTransform: 'uppercase',
    color: '#F9C242',
  },

  description: {
    fontSize: 16,
    fontWeight: '600',
    color: '#fff',
    textTransform: 'uppercase',
    marginTop: 10,
  },
  backdrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalImg: {
    width: 200,
    height: 100,
    resizeMode: 'cover',
    margin: 'auto',
    marginTop: 0,
  },
  closeIcon: {
    position: 'absolute',
    right: 10,
    top: 10,
  },
  closeIconWeb: {
    position: 'absolute',
    right: 10,
    top: 10,
    cursor: 'pointer',
  },

  button: {
    height: 31,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 'fit-content',
    paddingHorizontal: 15,
    borderRadius: 5,
    marginTop: 15,
    marginBottom: 30,
    backgroundColor: '#fff',
  },
  bottomText: {
    color: '#fff',
    fontSize: 12,
    textAlign: 'center',
    width: 221,
  },
});
export default ModalWinner;
