import React from 'react';
import { ButtonComp } from './Button.style';

const Button = ({
  children,
  onClick,
  color,
  width,
  background,
  align,
  type = 'button',
  style,
  disabled,
}) => (
    <ButtonComp
      width={width}
      color={color}
      style={style}
      type={type}
      disabled={disabled}
      background={background}
      onClick={onClick}
      align={align}
    >
      {children}
    </ButtonComp>
  );

export default Button;
