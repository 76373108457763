import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import Profile from "../screens/auth/Profile";
import Stream from "../screens/Stream/Stream";
import i18n from "../i18n/";

const ProfileStack = createStackNavigator();

function ProfileStackScreens() {
  return (
    <ProfileStack.Navigator>
      <ProfileStack.Screen
        name="Profile"
        component={Profile}
        options={{ headerShown: false }}
      />
      <ProfileStack.Screen
        name="createChannel"
        component={Stream}
        options={{
          headerShown: true,
          headerStyle: {
            backgroundColor: "#0F1425",
            elevation: 0,
            shadowOffset: { height: 0, width: 0 },
            borderBottomWidth: 0,
            borderBottomColor: "transparent",
            shadowColor: "transparent",
          },
          // headerBackImage: () => <BackHeaderArrow />,
          // headerBackTitleVisible: false,
          headerTitleStyle: {
            // fontFamily: "Aeonik-Bold",
            fontSize: 16,
            paddingTop: 0,
            alignSelf: "center",
          },
          headerTitleContainerStyle: {
            left: 0,
          },
          headerTintColor: "#E4E9F2",
          title: i18n.t("profile.stream"),
        }}
      />
    </ProfileStack.Navigator>
  );
}

export default ProfileStackScreens;
