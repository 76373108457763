import { Text } from '@ui-kitten/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Image, View, TouchableOpacity, Platform } from 'react-native';
import goldBadge from '../../../assets/goldBadge.png';
import pokyWinner from '../../../assets/pokyWinner.png';
import i18n from '../../../i18n/';

const WinnerReward = ({ onPressReward, userPrize }) => {
  const { t } = useTranslation(['winnerBanner']);

  return (
    <View
      style={{
        padding: 0,
        top: 0,
        position: 'absolute',
        width: '100%',
        left: 0,
        zIndex: 10,
      }}
    >
      <View
        style={{
          backgroundColor: '#FFB23D',
          height: 30,
          width: '100%',
          borderRadius: 3,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <View
          style={{ flexDirection: 'row', alignItems: 'center', marginLeft: 4 }}
        >
          <View
            style={{
              height: 25,
              width: 25,
              backgroundColor: '#192038',
              alignItems: 'center',
              justifyContent: 'center',
              marginRight: 4,
              borderRadius: 4,
            }}
          >
            <Image
              source={goldBadge}
              style={{ width: 14, height: 14, resizeMode: 'cover' }}
            />
          </View>
          <Text style={{ fontSize: 12, fontWeight: '500' }}>
            {Platform.OS === 'web' ? t('title') : i18n.t('winnerBanner.title')}
          </Text>
        </View>
        <View
          style={{ flexDirection: 'row', alignItems: 'center', marginRight: 3 }}
        >
          <TouchableOpacity onPress={() => onPressReward(userPrize)}>
            <Text style={{ fontSize: 12, fontWeight: '500', marginRight: 3 }}>
              {Platform.OS === 'web'
                ? t('claim')
                : i18n.t('winnerBanner.claim')}
            </Text>
          </TouchableOpacity>
          <Image
            source={pokyWinner}
            style={{ width: 14, height: 14, resizeMode: 'cover' }}
          />
        </View>
      </View>
    </View>
  );
};

export default WinnerReward;
