import React from "react";
import styled from "styled-components";
import { View, ImageBackground, Text } from "react-native";
import { Avatar } from "@ui-kitten/components";

const Champ = ({ img, name }) => (
  <ChampContainer>
    <Avatar source={{ uri: img }} ImageComponent={ImageBackground} />
    <ChampName>{name}</ChampName>
  </ChampContainer>
);

const ChampContainer = styled(View)`
  min-width: 70px;
  height: 80px;
  margin-bottom: 10px;
  margin-right: 10px;
  justify-content: center;
  align-items: center;
`;

const ChampName = styled(Text)`
  font-size: 10px;
  color: #fff;
  text-align: center;
  margin-top: 10px;
`;

export default Champ;
