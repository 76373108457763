import React, { useState, useEffect } from 'react';
import { transitions, positions, Provider } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';
import { Router, navigate } from '@reach/router';
import { ApplicationProvider, IconRegistry } from '@ui-kitten/components';
import { EvaIconsPack } from '@ui-kitten/eva-icons';
import { mapping, dark as darkTheme } from '@eva-design/eva';
import Challenge from './pages/Challenge';

import FirebaseService from './services/firebase.ts';
import PastChallenge from './pages/PastChallenges';
import Login from './pages/Login';
import GlobalStyles from './styles/GlobalStyles';
import Signup from './pages/Signup';
import './i18n/i18web';
import ChallengeDetails from './pages/ChallengeDetails';
import CreateSummoner from './pages/CreateSummoner';
import App from './App.tsx';
import ForgotPassword from './pages/ForgetPassword';
import Context from './Context';

FirebaseService.init();

const options = {
  timeout: 5000,
  position: positions.TOP_RIGHT,
  offset: '70px 20px 0',
  transition: transitions.SCALE,
};

const AppWeb = () => {
  const [isAuth, setIsAuth] = useState(false);
  useEffect(() => {
    if (window.innerWidth > 720) {
      FirebaseService.onAuthStateChanged((user) => {
        if (user) {
          setIsAuth(true);
          if (location.pathname === '/login') {
            navigate('/');
          }
        } else {
          setIsAuth(false);
        }
      });
    }
  }, []);
  if (window.innerWidth <= 720) {
    return <App />;
  }
  return (
    <Context.Provider>
      <Provider template={AlertTemplate} {...options}>
        <ApplicationProvider mapping={mapping} theme={darkTheme}>
          <IconRegistry icons={EvaIconsPack} />
          <GlobalStyles />
          {isAuth && (
            <Router>
              <Challenge path="/" />
              <PastChallenge path="history" />
              <ChallengeDetails path="/challenge/:slug" />
              <CreateSummoner path="/create-summoner" />
            </Router>
          )}
          {!isAuth && (
            <Router>
              <Challenge path="/" />
              <PastChallenge path="history" />
              <ChallengeDetails path="/challenge/:slug" />
              <CreateSummoner path="/create-summoner" />
              <Login path="/login" />
              <Signup path="/signup" />
              <ForgotPassword path="/reset-password" />
            </Router>
          )}
        </ApplicationProvider>
      </Provider>
    </Context.Provider>
  );
};
export default AppWeb;
