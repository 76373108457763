import React from 'react';
import { StyleSheet, View, Image } from 'react-native';
import { Text, Button } from '@ui-kitten/components';
import formatDistance from 'date-fns/formatDistance';
import isPast from 'date-fns/isPast';
import * as Localization from 'expo-localization';
import { es, enUS } from 'date-fns/locale';
import i18n from '../../../i18n';

const renderLocale = () =>
  Localization.locale.includes('es')
    ? { locale: es, addSuffix: true }
    : { locale: enUS, addSuffix: true };

const ChallengeCard = ({
  photoURL,
  startDate,
  endDate,
  onPress,
  title,
  title_es = 'Por definir',
}) => {
  const dateText = isPast(endDate)
    ? i18n.t('date.ended')
    : isPast(startDate)
    ? i18n.t('date.endsin')
    : i18n.t('date.startsin');

  return (
    <View>
      <View
        style={{
          borderColor: '#2e3a59',
          borderWidth: 1,
          borderRadius: 4,
          overflow: 'hidden',
          marginBottom: 10,
        }}
      >
        <Image
          style={styles.headerImage}
          source={{
            uri: photoURL,
          }}
        />
        <View style={styles.headerTextContainer}>
          <View style={styles.challengeTitle}>
            <Text category="c2">
              {Localization.locale.includes('es')
                ? String(title_es).toUpperCase()
                : String(title).toUpperCase()}
            </Text>
            <Text category="c1">
              {startDate
                ? `${dateText} ${formatDistance(
                    startDate,
                    new Date(),
                    renderLocale()
                  )}`
                : ''}
            </Text>
          </View>
          <Button size="tiny" onPress={onPress}>
            {i18n.t('history.buttonDetail')}
          </Button>
        </View>
      </View>
    </View>
  );
};

export default ChallengeCard;

const styles = StyleSheet.create({
  challengeInfo: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: -10,
    marginBottom: 18,
  },
  challengeTitle: { maxWidth: '67%' },
  headerImage: {
    height: 165,
    resizeMode: 'cover',
  },
  headerTextContainer: {
    paddingHorizontal: 24,
    paddingVertical: 8,
    backgroundColor: 'rgba(16,20,38,0.9)',
    marginTop: -48,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
});
