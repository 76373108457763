import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const emailRgx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const useInput = (initialState = '', type) => {
  const [value, setValue] = useState(initialState);
  const [error, setError] = useState('');
  const [touched, setTouched] = useState(false);
  const { t } = useTranslation('errors');

  useEffect(() => {
    if (touched && !value) {
      setError(t('inputEmpty'));
    }
    if (touched && type === 'email' && !emailRgx.test(value)) {
      setError(t('emailIsInvalid'));
    }
  }, [t, touched, type, value]);

  const onChange = e => {
    const { value: inputValue } = e.target;
    if (inputValue) {
      setError('');
    }
    setValue(inputValue);
  };

  const onTouch = () => {
    setTouched(true);
  };

  const onSetError = msg => {
    setError(msg);
  };

  return {
    value,
    error,
    onTouch,
    onChange,
    onSetError,
  };
};

export default useInput;
