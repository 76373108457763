import React, { useEffect } from "react";
import { View, Image, Platform } from "react-native";
import {
  Button,
  CheckBox,
  Input,
  StyleService,
  useStyleSheet,
  Text,
} from "@ui-kitten/components";
import { ImageOverlay } from "./extra/image-overlay.component";
import {
  EmailIcon,
  EyeIcon,
  EyeOffIcon,
  BackIcon,
} from "../../components/Icons";
import FirebaseService from "../../services/firebase";
import alert from "../../services/alert";
import openURL from "../../services/openURL";
//import firebase analytics
import * as Analytics from "expo-firebase-analytics";
import { useSafeArea } from "react-native-safe-area-context";
import { ScrollView, TouchableOpacity } from "react-native-gesture-handler";
import i18n from "../../i18n";

export default ({ navigation }): React.ReactElement => {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [termsAccepted, setTermsAccepted] = React.useState<boolean>(false);
  const [minAgeAccepted, setMinAge] = React.useState<boolean>(false);
  const [passwordVisible, setPasswordVisible] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const insets = useSafeArea();

  const styles = useStyleSheet(themedStyles);

  useEffect(() => {
    Analytics.logEvent("Sign_up", {
      screen: "Sign_up",
      purpose: "enter to screen Sign_up",
    });
  }, []);

  const onSignUpButtonPress = async (): Promise<void> => {
    if (!termsAccepted) {
      alert("Please accept the terms of use and conditions to continue");
      return;
    }
    if (!minAgeAccepted) {
      alert("You must be at least 17+ years old to continue");
      return;
    }
    setLoading(true);

    try {
      await FirebaseService.createUserWithEmailAndPassword(
        email.trim(),
        password
      );
      await Analytics.logEvent("Sign_up", {
        screen: "Sign_up",
        purpose: "Successful Sign_up",
      });
    } catch (error) {
      alert(error.message);
    }
    setLoading(false);
  };

  const onSignInButtonPress = (): void => {
    navigation && navigation.navigate("Login");
  };

  const onPasswordIconPress = (): void => {
    setPasswordVisible(!passwordVisible);
  };

  const onTermsButtonPress = (): void => {
    openURL("https://poky.gg/terms-of-use-and-conditions/");
  };

  return (
      <ImageOverlay
        style={styles.container}
        source={require("./assets/image-background.jpg")}
      >
        <ScrollView>
        <View
          style={{
            marginTop: 10 + insets.top,
            marginLeft: 10 + insets.left,
            alignSelf: "flex-start",
            width: 45,
          }}
        >
          <TouchableOpacity onPress={() => navigation.goBack()}>
            <BackIcon height={30} width={30} tintColor="white" />
          </TouchableOpacity>
        </View>
        <View style={styles.headerContainer}>
          <Image
            style={{
              resizeMode: "contain",
              height: 100,
              maxWidth: "80%",
              minWidth: 318,
            }}
            source={require("../../assets/logo.png")}
          />
          <Text style={styles.signInLabel} category="s1" status="control">
            {i18n.t("signup.signup")}
          </Text>
        </View>
        <View style={styles.formContainer}>
          <Input
            style={styles.formInput}
            status="control"
            autoCapitalize="none"
            placeholder={i18n.t("login.email")}
            icon={EmailIcon}
            value={email}
            onChangeText={(text) => setEmail(text)}
          />
          <Input
            style={styles.formInput}
            status="control"
            autoCapitalize="none"
            secureTextEntry={!passwordVisible}
            placeholder={i18n.t("login.password")}
            icon={passwordVisible ? EyeIcon : EyeOffIcon}
            value={password}
            onChangeText={setPassword}
            onIconPress={onPasswordIconPress}
          />
          <View style={styles.terms}>
            <CheckBox
              style={styles.termsCheckBox}
              textStyle={styles.termsCheckBoxText}
              text={i18n.t("signup.checkboxTerms")}
              checked={termsAccepted}
              onChange={(checked: boolean) => setTermsAccepted(checked)}
            />
            <Button
              style={styles.termsButton}
              appearance="ghost"
              status="control"
              onPress={onTermsButtonPress}
            >
              {i18n.t("signup.terms")}
            </Button>
          </View>
          <View style={styles.terms}>
            <CheckBox
              style={styles.termsCheckBox}
              textStyle={styles.termsCheckBoxText}
              text={i18n.t("signup.checkboxAge")}
              checked={minAgeAccepted}
              onChange={(checked: boolean) => setMinAge(checked)}
            />
          </View>
        </View>
        <Button
          style={styles.signUpButton}
          size="large"
          status="control"
          onPress={onSignUpButtonPress}
        >
          {loading ? i18n.t("summoner.loading") : i18n.t("signup.signupBtn")}
        </Button>
        <Button
          style={styles.signInButton}
          appearance="ghost"
          status="control"
          onPress={onSignInButtonPress}
        >
          {i18n.t("signup.loginLink")}
        </Button>
        </ScrollView>
      </ImageOverlay>
  );
};

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: "background-basic-color-1",
  },
  signInLabel: {
    marginTop: 16,
  },
  editAvatarButton: {
    width: 40,
    height: 40,
    borderRadius: 20,
  },
  headerContainer: {
    justifyContent: "center",
    alignItems: "center",
    minHeight: 216,
  },
  formContainer: {
    flex: 1,
    paddingTop: 0,
    paddingHorizontal: 16,
  },
  formInput: {
    marginTop: 16,
  },
  termsCheckBox: {
    marginTop: 24,
  },
  termsCheckBoxText: {
    color: "text-control-color",
  },
  signUpButton: {
    marginTop: 20,
    marginHorizontal: 16,
  },
  signInButton: {
    marginVertical: 12,
    marginHorizontal: 16,
  },
  termsButton: {
    marginHorizontal: -25,
    marginTop: 23,
  },
  terms: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
});
