import React, { useState } from "react";
import { Layout, Text, Input, Button } from "@ui-kitten/components";
import { StyleSheet } from "react-native";
import i18n from "../../i18n/";

const Stream = () => {
  const [channelUrl, setChannelUrl] = useState("");
  return (
    <>
      <Layout level="1" style={styles.contentContainer}>
        <Text category="s1" style={styles.description}>
          {i18n.t("stream.description")}
        </Text>
        <Text category="s1" style={styles.formLabel}>
          {i18n.t("stream.inputLabel")}
        </Text>
        <Input
          status="control"
          autoCapitalize="none"
          placeholder={i18n.t("stream.inputPlaceholder")}
          value={channelUrl}
          onChangeText={(text) => setChannelUrl(text)}
        />

        <Button
          style={styles.button}
          disabled={channelUrl.length > 0 ? false : true}
        >
          {i18n.t("stream.button")}
        </Button>
      </Layout>
    </>
  );
};

const styles = StyleSheet.create({
  contentContainer: {
    flex: 1,
    paddingHorizontal: 36,
    paddingTop: 32,
  },
  description: {
    fontSize: 14,
    color: "#E4E9F2",
  },
  formLabel: {
    marginTop: 24,
    fontWeight: "bold",
    marginBottom: 10,
  },
  button: {
    marginTop: 30,
    backgroundColor: "#3C25FA",
  },
});

export default Stream;
