import React from 'react';
import { Image, Platform, StyleSheet } from 'react-native';
import { Button, Layout, Modal, Text } from '@ui-kitten/components';
import poky from '../assets/poky.png';
import { AntDesign } from '@expo/vector-icons';
import i18n from '../i18n/';
import { useTranslation } from 'react-i18next';

export const ModalWarning = ({
  title = 'popupWarning.title',
  description = 'popupWarning.descripition',
  toggleModal,
  visible,
  coins,
}) => {
  const { t } = useTranslation(['popupWarning']);

  const renderModalElement = () => (
    <Layout level="3" style={styles.modalContainer}>
      <Text style={styles.title}>
        {Platform.OS === 'web' ? t(title.split('.')[1]) : i18n.t(title)}
      </Text>

      <Text style={styles.description}>
        {' '}
        {Platform.OS === 'web'
          ? t(description.split('.')[1])
          : i18n.t(description)}
      </Text>

      <Image source={poky} style={styles.modalImg} />

      <Text style={styles.description}>
        {Platform.OS === 'web'
          ? t('coinsNeedit')
          : i18n.t('popupWarning.coinsNeedit')}
        <Text style={{ fontSize: 20, fontWeight: '500' }}> {coins}</Text>
      </Text>
      <AntDesign
        name="close"
        size={12}
        color="#fff"
        style={Platform.OS === 'web' ? styles.closeIconWeb : styles.closeIcon}
        onPress={toggleModal}
      />
    </Layout>
  );

  return (
    <Layout style={styles.container}>
      <Modal
        backdropStyle={styles.backdrop}
        onBackdropPress={toggleModal}
        visible={visible}
      >
        {renderModalElement()}
      </Modal>
    </Layout>
  );
};

const styles = StyleSheet.create({
  container: {
    minHeight: 256,
    padding: 16,
    position: 'absolute',
  },
  modalContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    borderRadius: 5,
    minWidth: 320,
    maxWidth: 400,
    padding: 16,
    paddingTop: 40,
    backgroundColor: '#3C25FA',
  },
  title: {
    fontSize: 16,
    color: '#fff',
    fontWeight: '600',
  },

  description: {
    fontSize: 12,
    fontWeight: '400',
    color: '#fff',
    marginTop: 10,
  },
  backdrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalImg: {
    width: 200,
    height: 100,
    resizeMode: 'cover',
    margin: 'auto',
    marginTop: 0,
  },
  closeIcon: {
    position: 'absolute',
    right: 10,
    top: 10,
  },
  closeIconWeb: {
    position: 'absolute',
    right: 10,
    top: 10,
    // cursor: 'pointer',
  },
});
