import React, { useEffect, useState, useContext } from 'react';
import { StyleSheet, View } from 'react-native';
import { Text, Avatar, Button } from '@ui-kitten/components';
import CoinsLabel from './CoinsLabel';
import { useSafeArea } from 'react-native-safe-area-context';
import { AuthContext } from '../services/firebase';
import { useNavigation } from '@react-navigation/native';
import i18n from '../i18n';

const SummonerInfoHeader = () => {
  const insets = useSafeArea();
  const navigation = useNavigation();
  const { user, summoner, coins } = useContext(AuthContext);

  const onSignUpButtonPress = (): void => {
    navigation && navigation.navigate('SignUp');
  };

  const onLoginButtonPress = (): void => {
    navigation && navigation.navigate('Login');
  };

  return (
    <View style={[styles.headerContainer, { paddingTop: 24 + insets.top }]}>
      {user && user.photoURL ? (
        <Avatar
          source={{
            uri: user.photoURL,
          }}
        />
      ) : null}

      {!user && (
        <View style={styles.notLogged}>
          <Text style={{ marginRight: 10 }}>{i18n.t('global.notAuth')}</Text>
          <Button
            //appearance="ghost"
            status="primary"
            onPress={onLoginButtonPress}
            size="tiny"
          >
            {i18n.t('global.login')}
          </Button>
          <Text> / </Text>
          <Button
            //appearance="ghost"
            status="primary"
            onPress={onSignUpButtonPress}
            size="tiny"
          >
            {i18n.t('global.signup')}
          </Button>
        </View>
      )}

      {summoner ? (
        <View style={styles.info}>
          <Text style={styles.userLevel}>{summoner.summonerLevel}</Text>

          <Text category="s1">
            {`${summoner.name.toUpperCase()} (${summoner.region.toUpperCase()})`}
          </Text>
          <CoinsLabel coins={coins || 0} />
        </View>
      ) : null}
    </View>
  );
};

export default SummonerInfoHeader;

const styles = StyleSheet.create({
  headerContainer: {
    backgroundColor: '#101426',
    paddingHorizontal: 34,
    paddingBottom: 30,
    flexDirection: 'row',
  },
  info: {
    marginLeft: 12,
    position: 'relative',
  },
  notLogged: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  userLevel: {
    position: 'absolute',
    bottom: -5,
    height: 20,
    width: 20,
    left: -20,
    backgroundColor: '#ff7607',
    color: '#fff',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 50,
    textAlign: 'center',
    fontSize: 10,
  },
});
