export default {
  challenges: {
    currentChallenge: 'RETO ACTUAL',
    futureChanllenges: 'FUTUROS RETOS',
    notChallenges: 'No hay Retos disponibles',
  },
  nav: {
    history: 'HISTORIAL',
    challenge: 'RETOS',
    profile: 'PERFIL',
  },
  history: {
    pastChallenge: 'RETOS PASADOS',
    buttonDetail: 'VER DETALLES',
    refresh: 'ACTUALIZAR',
    refreshing: 'ACTUALIZANDO...',
  },
  profile: {
    profile: 'Perfil',
    share: 'Compartir',
    stream: 'Canal de Stream',
    rate: 'Califícanos',
    terms: 'Términos de uso y Condiciones',
    rules: 'Reglas generales de los retos',
    logout: 'Cerrar sesión',
  },
  stream: {
    inputPlaceholder: 'Agrega tu canal de Twitch o Facebook',
    description:
      'Quieres que transmitamos tus mejores jugadas y game plays por los canales de PokyGG? Por favor actualiza la URL de tu canal de Twitch o Facebook gaming.',
    button: 'Actualizar',
    inputLabel: 'URL del canal',
  },
  challengeDetails: {
    leaderboard: 'TABLA DE CLASIFICACION',
    description: 'DESCRIPCION',
    rules: 'PREMIOS Y REGLAS',
    rule: 'Reglas',
    loading: 'CARGANDO...',
    lastUpdate: 'Ultima vez actualizado:',
    join: 'UNIRSE',
    prizes: 'Premios',
    start: 'EMPIEZA',
    ends: 'FINALIZA',
    requirements: 'REQUERIMIENTOS',
    win: 'Ganar la partida',
    required: 'Requerido',
    notRequired: 'No Requerido',
    place: 'Lugar',
    first: 'Primer',
    second: 'Segundo',
    third: 'Tercero',
    champions: 'CAMPEONES',
    blocked: 'Bloqueados',
    allowed: 'Permitidos',
    goToMatch: 'Ir al detalle de la partida',
    goToStream: 'Ir al canal de streaming',
    cancel: 'Cancelar',
  },
  login: {
    login: 'Iniciar sesión en tu cuenta',
    email: 'Correo electrónico',
    password: 'Contraseña',
    loginBtn: 'Iniciar sesión',
    signupLink: 'Aun no estas registrado? Regístrate',
    loginInfo:
      'POKY.GG no cuenta con el respaldo de Riot Games y no refleja los puntos de vista u opiniones de Riot Games o de cualquier persona oficialmente involucrada en la producción o administración de las propiedades de Riot Games. Riot Games y todas las propiedades asociadas son marcas comerciales o marcas comerciales registradas de Riot Games, Inc.',
    loginInfo2:
      'Apple no es un patrocinador ni está involucrado de ninguna manera con los desafíos de POKY.GG.',
    forgotPassword: 'Olvidaste tu Contraseña?',
    resetPassword: 'Recupera tu Contraseña',
    PasswordReseted: 'se ha enviado un correo a: ',
    PasswordReseted2: ' para recuperar tu Contraseña',
  },
  signup: {
    signup: 'Regístrate para disfrutar de los retos',
    checkboxTerms: 'Lei y acepto los',
    terms: 'Términos de uso y Condiciones',
    checkboxAge: 'Soy mayor de 17 años',
    signupBtn: 'Registrarse',
    loginLink: 'Ya tienes una cuenta? Inicia sesión',
    password: 'Contraseña',
    passwordErr: 'La Contraseña debe tener almenos 6 caracteres',
  },
  global: {
    notAuth: 'Sesión no iniciada',
    signup: 'REGÍSTRATE',
    login: 'INICIAR SESIÓN',
    logout: 'CERRAR SESIÓN',
  },
  summoner: {
    description: `¡Estás casi listo !, actualmente solo se admiten usuarios de LAN, LAS y NA, así que solo asegúrate de estar usando tu nombre de invocador correcto`,
    name: 'Nombre de invocador',
    server: 'Servidor',
    userAlreadyTaken: 'Este invocador ya pertenece a otra cuenta',

    loading: 'Cargando',
    confirm: 'Confirmar',
    logout: `No quieres continuar? Cierra sesión`,
  },
  date: {
    ended: 'Finalizado',
    endsin: 'Finaliza ',
    startsin: 'Empieza ',
  },
  poppuWinner: {
    button: 'Un miembro te contactara pronto!',
  },
  joinPopup: {
    title: '¿LISTO PARA ENTRAR?',
    description1: '¿Quieres gastar',
    description2: ' Poky coins para entrar a este challenge?',
    userPokys: 'Tienes: ',
    button: 'SI, ENTRAR',
    titlev2: 'OH NO, NECESITAS MAS POKY COINS!',
    descriptionv2:
      'Sigue jugando Challenges gratis para ganar Poky Coins! O ve a la tienda y compra un paqueta de Poky Coins',
    storeButton: 'Ir a la tienda',
    freePokysLink: 'Mira un video y obtén Poky GRATIS',
    wrongLevel:
      'Lo sentimos, no puedes entrar a este challenge, debes ser almenos nivel ',
  },
  errors: {
    inputEmpty: 'Este Campo es requerido',
    emailNotRegister: 'Correo o contraseña invalido',
    wrongPassword: 'Correo o contraseña invalido',
    notSummoner: 'Este invocador ya esta en uso o no existe',
    emailRegisterd: 'Este correo ya está en uso',
    emailIsInvalid: 'Este correo es invalido',
  },
  share: {
    message:
      'Consigue RP cada semana participando en los challenge y ayuda a tus amigos a quedar en los primero lugares!',
  },
  popupWarning: {
    title: 'OH NO, NECECISTAS MAS POKY COINS!',
    descripition: '!Sigue Jugando!',
    coinsNeedit: 'Poky Coins requeridas:',
  },
  popupWinner: {
    congrats: 'Felicidades!',
    description: 'Has sido ganador de un reto!',
    won: 'Has ganado',
    claim: 'Reclamar Coins',
    bottomText:
      'Si tambien has ganado Riot Points, Seras contactado muy pronto por nosotros.',
  },
  winnerBanner: {
    title: 'Fuistes un ganador',
    claim: 'OBTENER POKYCOINS',
  },
};
