import React, { useEffect, useState, useContext, useCallback } from 'react';
import { StyleSheet, View, ScrollView, Platform } from 'react-native';
import { AdMobInterstitial } from 'expo-ads-admob';
import {
  Layout,
  Button,
  Tab,
  TabView,
  Text,
  Card,
  Avatar,
} from '@ui-kitten/components';
import LeaderboardList from './extra/LeaderboardList';
import ChallengeDetailsHeader from './extra/ChallengeDetailsHeader';
import FirebaseService, { AuthContext } from '../../services/firebase';
import { LeaderBoard } from '../../types';
import * as Localization from 'expo-localization';
import { getFilenameFromURL } from '../../utils';
import AdsBanner from '../../components/AdsBanner';
import alert from '../../services/alert';
import * as Analytics from 'expo-firebase-analytics';
import isPast from 'date-fns/isPast';
import { formatDistance } from 'date-fns';
import { es, enUS } from 'date-fns/locale';
import i18n from '../../i18n';
import VideoPlayer from '../../components/VideoPlayer';
import moment from 'moment';
import useGetChallengesChampions from '../../hooks/useGetChallengeChampions.js';
import Champ from '../../components/Champ';
import goldBadge from '../../assets/goldBadge.png';
import silverBadge from '../../assets/silverBadge.png';
import bronceBadge from '../../assets/bronceBadge.png';
import badge from '../../assets/badge.png';
import JoinChallengeButton from '../../components/JoinChallengeButton';
import { ModalWarning } from '../../components/ModalApp';

const renderLocale = () =>
  Localization.locale.includes('es')
    ? { locale: es, addSuffix: true }
    : { locale: enUS, addSuffix: true };

const ChallengeDetails = ({ route, navigation }) => {
  const [loading, setLoading] = useState(false);
  const [challenge, setChallenge] = useState(route.params?.challenge ?? {});
  const [leaderboard, setLeaderboard] = useState<LeaderBoard>();
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const { user, summoner, setCoins, coins } = useContext(AuthContext);
  const participantId = summoner
    ? (summoner.name + '-' + summoner.region).toUpperCase()
    : null;
  const [refreshing, setRefreshing] = useState(false);
  const isCurrentOrPast = challenge ? isPast(challenge.startDate) : false;
  const { challengeChamps } = useGetChallengesChampions(
    challenge?.requirements?.conditions?.[1]?.value
  );

  const [visible, setVisible] = React.useState(false);
  const toggleModal = () => {
    setVisible(!visible);
  };

  const getLeaderboard = async () => {
    try {
      const leaderboardData: LeaderBoard = await FirebaseService.getLeaderboard(
        challenge.id
      );
      if (
        participantId &&
        challenge.participants &&
        challenge?.participants[participantId] &&
        !leaderboardData.leaderboard.find(
          (element) =>
            element?.name?.toUpperCase() === summoner?.name?.toUpperCase() &&
            element?.region?.toUpperCase() === summoner?.region?.toUpperCase()
        )
      ) {
        setLeaderboard({
          ...leaderboardData,
          leaderboard: [
            ...leaderboardData.leaderboard,
            {
              name: user.displayName,
              profileIconId: getFilenameFromURL(user.photoURL),
            },
          ],
        });
      } else {
        setLeaderboard(leaderboardData);
      }
    } catch (error) {
      alert(error);
    }
  };

  useEffect(() => {
    (async function () {
      await getLeaderboard();
    })();
  }, []);

  const refreshLeaderboard = useCallback(async () => {
    setRefreshing(true);
    const responseData = await FirebaseService.calculateChallengeLeaderboard(
      challenge.id
    );
    setLeaderboard(responseData?.data ?? leaderboard);
    setRefreshing(null);
  }, [setLeaderboard]);

  const onRefresh = useCallback(async () => {
    try {
      await refreshLeaderboard();
      await AdMobInterstitial.setAdUnitID(
        Platform.OS === 'ios'
          ? 'ca-app-pub-4141016175538594/2512084946'
          : 'ca-app-pub-4141016175538594/5958900586'
      );
      await AdMobInterstitial.requestAdAsync({ servePersonalizedAds: true });
      await AdMobInterstitial.showAdAsync();
      await Analytics.logEvent('LeaderBoard_Refresh', {
        purpose: 'Request LeaderBoard_Refresh',
        summoner: summoner.name,
      });
    } catch (error) {
      console.warn(error);
    }
  }, [refreshLeaderboard]);

  const onSubscribe = useCallback(async () => {
    if (!user) {
      navigation && navigation.navigate('Login');
      return;
    }

    if (!summoner) {
      navigation && navigation.navigate('SetSummoner');
      return;
    }

    if (summoner.coins) {
      if (summoner.coins < parseInt(challenge.coins)) {
        toggleModal();
        return;
      }
    } else {
      toggleModal();
      return;
    }

    if (
      summoner.summonerLevel < challenge.requirements?.join?.min_summoner_level
    ) {
      alert(
        `${i18n.t('joinPopup.wrongLevel')}${
          challenge.requirements?.join?.min_summoner_level
        }`
      );
      return;
    }

    try {
      await AdMobInterstitial.setAdUnitID(
        Platform.OS === 'ios'
          ? 'ca-app-pub-4141016175538594/2512084946'
          : 'ca-app-pub-4141016175538594/5958900586'
      );

      await AdMobInterstitial.requestAdAsync({ servePersonalizedAds: true });
      await AdMobInterstitial.showAdAsync();
    } catch (error) {
      console.log(error);
    }
    setLoading(true);
    try {
      const subscribeResponse = await FirebaseService.subscribeToChallenge(
        challenge.id
      );

      const challengeData = subscribeResponse?.data?.challenge;
      setChallenge(challengeData);

      await Analytics.logEvent('Join_Challenge', {
        purpose: 'Successful Join a Challenge',
        summoner: summoner.name,
      });
      const leaderboardData: LeaderBoard = await FirebaseService.getLeaderboard(
        challenge.id
      );
      setCoins(parseInt(coins) - challenge.coins);
      setLeaderboard({
        ...leaderboardData,
        leaderboard: [
          ...leaderboardData.leaderboard,
          {
            name: user.displayName,
            profileIconId: getFilenameFromURL(user.photoURL),
          },
        ],
      });
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }, [JSON.stringify(challenge), leaderboard]);

  const renderJoin = useCallback(() => {
    if (!challenge.active) {
      return null;
    }

    if (challenge.participants && challenge.participants[participantId]) {
      return refreshing !== null ? (
        <Button
          onPress={onRefresh}
          style={styles.refreshBtn}
          disabled={refreshing}
        >
          {refreshing
            ? i18n.t('history.refreshing')
            : i18n.t('history.refresh')}
        </Button>
      ) : null;
    } else {
      console.log('challenge', JSON.stringify(challenge));
      return (
        <JoinChallengeButton
          coins={challenge.coins}
          onPress={onSubscribe}
          style={styles.joinBtn}
        />
      );
    }
  }, [
    JSON.stringify(challenge),
    loading,
    onSubscribe,
    refreshing,
    user,
    summoner,
  ]);

  const renderPrizesFromObject = useCallback(
    (prizes) => (
      <Card>
        <Text>{i18n.t('challengeDetails.prizes')}</Text>
        <Text>{`${i18n.t('challengeDetails.first')} ${i18n.t(
          'challengeDetails.place'
        )}: ${challenge.prizes.first}`}</Text>
        <Text>{`${i18n.t('challengeDetails.first')} ${i18n.t(
          'challengeDetails.place'
        )}: ${challenge.prizes.second}`}</Text>
        <Text>{`${i18n.t('challengeDetails.first')} ${i18n.t(
          'challengeDetails.place'
        )}: ${challenge.prizes.third}`}</Text>
      </Card>
    ),
    []
  );

  const renderBadge = (position) => {
    if (position === 1) {
      return (
        <Avatar
          size="tiny"
          shape="square"
          source={goldBadge}
          style={{ resizeMode: 'contain' }}
        />
      );
    }
    if (position === 2) {
      return (
        <Avatar
          size="tiny"
          shape="square"
          source={silverBadge}
          style={{ resizeMode: 'contain' }}
        />
      );
    }
    if (position === 3) {
      return (
        <Avatar
          size="tiny"
          shape="square"
          source={bronceBadge}
          style={{ resizeMode: 'contain' }}
        />
      );
    }

    return (
      <Avatar
        size="tiny"
        shape="square"
        source={badge}
        style={{ resizeMode: 'contain' }}
      />
    );
  };

  const renderPrizesFromArray = useCallback(
    (prizes) => (
      <Card>
        <Text>{i18n.t('challengeDetails.prizes')}</Text>
        <ScrollView>
          {prizes.map((prize, index) => (
            <View style={styles.container}>
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                {renderBadge(index + 1)}
                <Text style={{ marginLeft: 10 }}>
                  {index + 1}° {i18n.t('challengeDetails.place')}
                </Text>
              </View>
              <Text>{`  ${prize.value} ${prize.type}.`}</Text>
            </View>
          ))}
        </ScrollView>
      </Card>
    ),
    []
  );

  const renderPrizes = useCallback((prizes) => {
    if (!prizes) {
      return null;
    }
    return Array.isArray(prizes)
      ? renderPrizesFromArray(prizes)
      : renderPrizesFromObject(prizes);
  }, []);

  return (
    <>
      <ChallengeDetailsHeader
        title={challenge.title}
        startDate={challenge.startDate}
        endDate={challenge.endDate}
        photoURL={challenge.photoURL}
        active={challenge.active}
        title_es={challenge.title_es}
        excerpt={challenge.excerpt}
        excerpt_es={challenge.excerpt_es}
        challenge={challenge}
      />
      {visible && (
        <ModalWarning
          toggleModal={toggleModal}
          visible={visible}
          coins={
            summoner.coins
              ? parseInt(challenge.coins) - summoner.coins
              : parseInt(challenge.coins)
          }
        />
      )}
      <Layout level="1" style={styles.contentContainer}>
        <>
          <TabView
            style={{ flex: 1, marginTop: 10 }}
            selectedIndex={selectedIndex}
            onSelect={setSelectedIndex}
          >
            {isCurrentOrPast ? (
              <Tab title={i18n.t('challengeDetails.leaderboard')}>
                <View>
                  {leaderboard && leaderboard.lastUpdate ? (
                    <Text category="c2" style={styles.lastUpdate}>
                      {i18n.t('challengeDetails.lastUpdate')}:{' '}
                      {formatDistance(
                        leaderboard.lastUpdate,
                        new Date(),
                        renderLocale()
                      )}
                    </Text>
                  ) : null}
                  <LeaderboardList
                    data={leaderboard?.leaderboard}
                    challenge={challenge}
                    refresh={onRefresh}
                  />
                </View>
              </Tab>
            ) : null}
            <Tab title={i18n.t('challengeDetails.description')}>
              <Layout style={styles.tabContainer}>
                <ScrollView>
                  <Card>
                    <Text>
                      {Localization.locale.includes('es')
                        ? challenge.description_es
                        : challenge.description}
                    </Text>
                  </Card>
                  <View style={styles.container}>
                    <View>
                      <Text style={styles.dateLabel}>Start</Text>
                      <Text>
                        {moment(challenge.startDate).format('DD/MM/YYYY')}
                      </Text>
                      <Text>
                        {moment(challenge.startDate).format('hh:mm a')}
                      </Text>
                    </View>
                    <View>
                      <Text style={styles.dateLabel}>End</Text>
                      <Text>
                        {moment(challenge.endDate).format('DD/MM/YYYY')}
                      </Text>
                      <Text>{moment(challenge.endDate).format('hh:mm a')}</Text>
                    </View>
                  </View>
                  <View style={{ marginTop: 20 }}>
                    <AdsBanner
                      style={{
                        backgroundColor: '#101426',
                      }}
                    />
                  </View>

                  <View style={styles.containerVideos}>
                    {challenge?.gallery?.map((video: string, i: number) => (
                      <VideoPlayer
                        source={video}
                        marginRight={i % 0 ? 10 : 0}
                      />
                    ))}
                  </View>
                  <Text style={styles.sectionLabel}>Requirements</Text>
                  <View style={styles.container}>
                    <Text>Win the match</Text>
                    <Text>
                      {challenge.requirements?.conditions[0].value
                        ? 'Required'
                        : 'Not required'}
                    </Text>
                  </View>
                  <View style={styles.container}>
                    <Text>Champions</Text>
                    <Text>
                      {challenge?.requirements?.conditions?.[1]?.operator
                        ? 'Blocked'
                        : 'Allowed'}
                    </Text>
                  </View>
                  <ScrollView style={{ flexDirection: 'row' }} horizontal>
                    {challengeChamps?.map((champ, i) => (
                      <Champ img={champ.img} name={champ.name} key={i} />
                    ))}
                  </ScrollView>
                </ScrollView>
              </Layout>
            </Tab>
            {challenge.prizes || challenge.rules ? (
              <Tab title={i18n.t('challengeDetails.rules')}>
                <ScrollView>
                  <Layout style={styles.tabContainer}>
                    {challenge.prizes ? renderPrizes(challenge.prizes) : null}
                    <View style={{ marginTop: 10, marginBottom: 10 }}>
                      <AdsBanner
                        style={{
                          backgroundColor: '#101426',
                        }}
                      />
                    </View>
                    {Localization.locale.includes('es') &&
                    challenge.rules_es ? (
                      <Card>
                        <Text>{i18n.t('challengeDetails.rule')}</Text>
                        {challenge.rules_es.map((rule) => (
                          <Text>- {rule}</Text>
                        ))}
                      </Card>
                    ) : null || challenge.rules ? (
                      <Card>
                        <Text>{i18n.t('challengeDetails.rule')}</Text>
                        {challenge.rules.map((rule) => (
                          <Text>- {rule}</Text>
                        ))}
                      </Card>
                    ) : null}
                  </Layout>
                </ScrollView>
              </Tab>
            ) : null}
          </TabView>
          {renderJoin()}
        </>
      </Layout>
    </>
  );
};

const styles = StyleSheet.create({
  contentContainer: {
    flex: 1,
    paddingHorizontal: 12,
  },
  tabContainer: {
    minHeight: 64,
  },
  joinBtn: {
    marginTop: 10,
    marginBottom: 16,
  },
  refreshBtn: {
    marginBottom: 16,
  },
  lastUpdate: {
    color: '#8f9bb3',
    textAlign: 'center',
  },
  container: {
    marginTop: 20,
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  dateLabel: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  sectionLabel: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 15,
    marginTop: 15,
  },
  containerVideos: {
    width: '100%',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
});

export default ChallengeDetails;
