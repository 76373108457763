import React from 'react';
import { TouchableHighlight, View } from 'react-native';
import { Text, withStyles } from '@ui-kitten/components';
import i18n from '../i18n';
import PokyCoinsLabel from './PokyCoinsLabel';

function JoinChallengeButton(props: any) {
  return (
    <TouchableHighlight
      activeOpacity={0.9}
      underlayColor={props.themedStyle.buttonPressed.backgroundColor}
      {...props}
      style={[props.themedStyle.button, props.style]}
    >
      <View style={{ alignItems: 'center', flexDirection: 'row' }}>
        <Text category="s1">
          {i18n.t(props.loading ? 'summoner.loading' : 'challengeDetails.join')}
        </Text>
        {props.coins && !props.loading ? (
          <PokyCoinsLabel coins={props.coins} />
        ) : null}
      </View>
    </TouchableHighlight>
  );
}

const ThemedJoinChallengeButton = withStyles(JoinChallengeButton, theme => ({
  button: {
    backgroundColor: theme['color-primary-500'],
    minHeight: 40,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
  },
  buttonPressed: {
    backgroundColor: theme['color-primary-600'],
  },
}));

export default ThemedJoinChallengeButton;
