import React from 'react';
import { StyleSheet, View, Image, Platform } from 'react-native';
import { Text, Button } from '@ui-kitten/components';
import Color from 'color';
import formatDistance from 'date-fns/formatDistance';
import * as Localization from 'expo-localization';
import i18n from '../../../i18n';
import { es, enUS } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';
import i18next from '../../../i18n/i18web.js';
import WinnerReward from './WinnerReward';

const renderLocale = () =>
  Localization.locale.includes('es') || i18next.languages[0].includes('es')
    ? { locale: es, addSuffix: true }
    : { locale: enUS, addSuffix: true };

const ChallengeCard = ({
  photoURL,
  endDate,
  onPress,
  title,
  title_es,
  userPrize,
  onPressReward,
}) => {
  const { t } = useTranslation(['date', 'history']);
  return (
    <View>
      <View
        style={{
          borderColor: '#2e3a59',
          borderWidth: 1,
          borderRadius: 4,
          overflow: 'hidden',
          marginBottom: 10,
        }}
      >
        {userPrize && userPrize?.pending && (
          <WinnerReward onPressReward={onPressReward} userPrize={userPrize} />
        )}

        <Image
          style={styles.headerImage}
          source={{
            uri: photoURL,
          }}
        />
        <View style={styles.headerTextContainer}>
          <View style={styles.challengeTitle}>
            <Text category="c2">
              {Localization.locale.includes('es') ||
              i18next.languages[0].includes('es')
                ? (title_es && String(title_es).toUpperCase()) ||
                  String(title).toUpperCase()
                : String(title).toUpperCase()}
            </Text>
            {Platform.OS === 'web' ? (
              <Text category="c1">
                {endDate
                  ? `${t('ended')} ${formatDistance(
                      endDate,
                      new Date(),
                      renderLocale()
                    )}`
                  : null}
              </Text>
            ) : (
              <Text category="c1">
                {endDate
                  ? `${i18n.t('date.ended')} ${formatDistance(
                      endDate,
                      new Date(),
                      renderLocale()
                    )}`
                  : null}
              </Text>
            )}
          </View>
          {Platform.OS === 'web' ? (
            <Button style={styles.button} size="tiny" onPress={onPress}>
              {t('history:buttonDetail')}
            </Button>
          ) : (
            <Button style={styles.button} size="tiny" onPress={onPress}>
              {i18n.t('history.buttonDetail')}
            </Button>
          )}
        </View>
      </View>
    </View>
  );
};

export default ChallengeCard;

const styles = StyleSheet.create({
  challengeInfo: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: -10,
    marginBottom: 18,
  },
  button: {},
  challengeTitle: { maxWidth: '67%' },
  headerImage: {
    height: 165,
    resizeMode: 'cover',
  },
  headerTextContainer: {
    paddingHorizontal: 24,
    paddingVertical: 8,
    backgroundColor: 'rgba(16,20,38,0.9)',
    marginTop: -48,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
});
