import styled from 'styled-components';

export const ButtonComp = styled.button`
  width: ${props => props.width || 'fit-content'};
  padding: 10px;
  color: ${props => props.color || '#fff'};
  background: ${props => props.background || '#4609ff'};
  border-radius: 5px;
  cursor: pointer;
  border: 0;
  font-weight: bold;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: ${props => props.align || 'center'};
`;
