import React from 'react';
import { ApplicationProvider, IconRegistry } from '@ui-kitten/components';
import { EvaIconsPack } from '@ui-kitten/eva-icons';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { mapping, dark as darkTheme } from '@eva-design/eva';
import OneSignal from 'react-native-onesignal';
import Sentry from './utils/sentry';
import Root from './router/Root';
import FirebaseService from './services/firebase';
import { StatusBar } from 'expo-status-bar';
import { default as theme } from './theme.json';

OneSignal.init('9b6a648a-c294-4d97-9f71-b806c6092c5b', {
  kOSSettingsKeyAutoPrompt: true,
});
OneSignal.inFocusDisplaying(2);
OneSignal.requestPermissions();

FirebaseService.init();
if (__DEV__) {
  console.disableYellowBox = true;
  console.ignoredYellowBox = ['Warning: Each', 'Warning: Failed'];
}

Sentry.init({
  dsn:
    'https://db261c862aa74859a29b7fd5b9f04c14@o181748.ingest.sentry.io/5245626',
  enableInExpoDevelopment: __DEV__,
  debug: true,
});

const App = () => {
  return (
    <ApplicationProvider mapping={mapping} theme={{...darkTheme, ...theme}}>
      <IconRegistry icons={EvaIconsPack} />
      <StatusBar style="auto" />
      <SafeAreaProvider>
        <Root />
      </SafeAreaProvider>
    </ApplicationProvider>
  );
};

export default App;
