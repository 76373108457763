import React from 'react';
import styled from 'styled-components';

const Input = ({
  placeholder,
  onTouchInput,
  onClickIcon,
  onChange,
  Icon,
  value,
  secureInput,
}) => (
    <InputWrapper>
      <InputField
        type={secureInput ? 'password' : 'text'}
        placeholder={placeholder}
        onClick={onTouchInput}
        onChange={onChange}
        value={value}
        autoComplete="off"
      />
      {Icon && <Icon onClick={onClickIcon} />}
    </InputWrapper>
  );

const InputWrapper = styled.div`
  width: 100%;
  min-height: 40px;
  border-radius: 5px;
  padding: 7px 8px;
  background-color: rgba(255, 255, 255, 0.24);
  border-color: rgba(255, 255, 255);
  margin-bottom: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  div {
    width: 30px;
    > svg {
      fill: #fff;
    }
  }
`;

const InputField = styled.input`
  font-size: 15px;
  font-weight: normal;
  background: transparent;
  border: 0;
  outline: 0;
  margin-right: 8px;
  margin-left: 8px;
  color: #fff;
  width: 100%;
  ::placeholder {
    color: #fff;
  }
`;

export default Input;
