import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Text } from '@ui-kitten/components';
import { CoinsIcon } from './Icons';

const CoinsLabel = ({ coins, prefix = '', subfix = '' }) => {
  return (
    <View style={styles.container}>
      <CoinsIcon style={styles.coinsIcon} />
      <Text category="c1">
        {prefix ? `${prefix} ` : ''}
        {Number(coins).toLocaleString()} Poky Coins
        {subfix ? ` ${subfix} ` : ''}
      </Text>
    </View>
  );
};

export default CoinsLabel;

const styles = StyleSheet.create({
  coinsIcon: {
    marginTop: 2,
    marginRight: 5,
  },
  container: {
    flexDirection: 'row',
  },
});
