import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { formatDistance } from 'date-fns';
import { TabView, Tab } from '@ui-kitten/components';
import moment from 'moment';
import { navigate } from '@reach/router';
import { Platform } from 'react-native';
import { useTranslation } from 'react-i18next';
import { es, enUS } from 'date-fns/locale';
import useGetChallenge from '../hooks/useGetChallenge';
import Layout from '../components/Layout/Layout';
import { SectionName } from '../styles/Shared';
import useGetChallengeLeaderboard from '../hooks/useGetChallengeLeaderboard';
import LeaderBoardList from '../components/LeaderboardList';
import Loading from '../components/Loading';
import Button from '../components/Button/Button';
import useCurrentUser from '../hooks/useCurrentUser';
import useSummoner from '../hooks/useSummoner';
import useGetChallengesChampions from '../hooks/useGetChallengeChampions';
import Champ from '../components/Champ';
import goldBadge from '../assets/goldBadge.png';
import silverBadge from '../assets/silverBadge.png';
import bronceBadge from '../assets/bronceBadge.png';
import badge from '../assets/badge.png';
import AdsBanner from '../components/AdsBanner.web.tsx';
import i18n from '../i18n/i18web';
import { getIdFromSlug } from '../utils/index';
import { ModalWarning } from '../components/ModalApp.tsx';
import PokyCoinsLabel from '../components/PokyCoinsLabel.tsx';
import { Context } from '../Context';
const renderLocale = () =>
  i18n.languages[0].includes('es')
    ? { locale: es, addSuffix: true }
    : { locale: enUS, addSuffix: true };

const ChallengeDetails = ({ slug, location }) => {
  const challenge = useGetChallenge(getIdFromSlug(slug));
  const { id: userId, name } = useCurrentUser();

  const { t } = useTranslation([
    'challengeDetails',
    'date',
    'joinPopup',
    'history',
  ]);
  const { coins, setCoins } = useContext(Context);
  const [visible, setVisible] = React.useState(false);
  const toggleModal = () => {
    setVisible(!visible);
  };

  const leaderboard = useGetChallengeLeaderboard(getIdFromSlug(slug));
  const [selectedIndex, setSelectedIndex] = useState(0);
  const { summoner } = useSummoner(userId);

  const { challengeChamps } = useGetChallengesChampions(
    challenge?.requirements?.conditions?.[1]?.value
  );

  const participantId = summoner
    ? `${summoner.name}-${summoner.region}`.toUpperCase()
    : null;

  const onRenderDate = () => {
    const now = Date.now();

    if (now > challenge.endDate) {
      return `${t('date:ended')}  ${formatDistance(
        challenge.endDate,
        new Date(),
        renderLocale()
      )}`;
    }

    return challenge.active
      ? `${t('date:endsin')}  ${formatDistance(
          challenge.endDate,
          new Date(),
          renderLocale()
        )}`
      : `${t('date:startsin')} ${formatDistance(
          challenge.startDate,
          new Date(),
          renderLocale()
        )}`;
  };

  const onSubToChallenge = async () => {
    if (!userId) {
      navigate('/login');
      return;
    }

    if (!summoner) {
      navigate('/create-summoner');
      return;
    }

    if (typeof challenge?.coins === 'number') {
      if (typeof summoner?.coins === 'number') {
        if (summoner.coins < parseInt(challenge.coins)) {
          toggleModal();
          return;
        }
      } else {
        toggleModal();
        return;
      }
    }

    if (
      summoner.summonerLevel < challenge.requirements?.join?.min_summoner_level
    ) {
      alert(
        `${t('joinPopup:wrongLevel')}${
          challenge.requirements.join.min_summoner_level
        }`
      );
      return;
    }

    const challengeData = await leaderboard.onSubscribeToChallenge();
    setCoins(parseInt(coins) - challenge.coins);
    challenge.setChallenge(challengeData);
    leaderboard.setLeaderboardOnSubscribe();
  };

  const renderBadge = (position) => {
    if (position === 1) {
      return <BadgePrize badge={goldBadge} />;
    }
    if (position === 2) {
      return <BadgePrize badge={silverBadge} />;
    }
    if (position === 3) {
      return <BadgePrize badge={bronceBadge} />;
    }

    return <BadgePrize badge={badge} />;
  };

  const renderJoin = () => {
    if (!challenge.active) {
      return null;
    }

    if (challenge.participants && challenge.participants[participantId]) {
      return leaderboard.refreshing !== null ? (
        <Button
          width="350px"
          style={{ margin: 'auto' }}
          background="#3c25fa"
          color="#fff"
          disabled={leaderboard.refreshing}
          onClick={leaderboard.onRefresh}
          dis
        >
          {leaderboard.refreshing
            ? t('history:refreshing')
            : t('history:refresh')}
        </Button>
      ) : null;
    }
    return (
      <Button
        width="350px"
        style={{ margin: 'auto' }}
        background="#3c25fa"
        color="#fff"
        onClick={onSubToChallenge}
      >
        {leaderboard.joining ? t('loading') : t('join')}
        {challenge.coins && !leaderboard.joining ? (
          <PokyCoinsLabel coins={challenge.coins} />
        ) : null}
      </Button>
    );
  };
  return (
    <Layout>
      {challenge.loading ? (
        <Loading />
      ) : (
        <WrapperDetails>
          {visible && (
            <ModalWarning
              toggleModal={toggleModal}
              visible={visible}
              coins={
                summoner.coins
                  ? parseInt(challenge.coins) - summoner.coins
                  : parseInt(challenge.coins)
              }
            />
          )}
          <WrapperDetail>
            <WrapperImg>
              <ChallengeImg src={challenge.photoURL} />
              <ChallengeImgInfo>
                <ChallengeName>
                  {!i18n.languages[0].includes('es')
                    ? challenge.title
                    : challenge.title_es}
                </ChallengeName>
                <span>{challenge.endDate && onRenderDate()}</span>
              </ChallengeImgInfo>
            </WrapperImg>
            <TabView
              selectedIndex={selectedIndex}
              onSelect={setSelectedIndex}
              style={{ backgroundColor: '#161D32' }}
            >
              <Tab title={t('description')}>
                <TabContainer>
                  <ChallengeText>
                    {' '}
                    {!i18n.languages[0].includes('es')
                      ? challenge.description
                      : challenge.description_es}
                  </ChallengeText>
                  <DateContainer>
                    <div>
                      <SectionName>{t('start')}</SectionName>
                      <ChallengeText>
                        {moment(challenge.startDate).format(
                          'DD/MM/YYYY - hh:mm a'
                        )}
                      </ChallengeText>
                    </div>
                    <div>
                      <SectionName>{t('ends')}</SectionName>
                      <ChallengeText>
                        {moment(challenge.endDate).format(
                          'DD/MM/YYYY - hh:mm a'
                        )}
                      </ChallengeText>
                    </div>
                  </DateContainer>
                  <VideoContainers>
                    {challenge?.gallery?.map((video) => (
                      <Video
                        key={video}
                        src={video}
                        frameborder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      />
                    ))}
                  </VideoContainers>
                  <SectionName>{t('requirements')}</SectionName>
                  <FlexBorderText>
                    <span>{t('win')}</span>
                    <span>
                      {challenge.requirements?.conditions[0].value
                        ? t('required')
                        : t('notRequired')}
                    </span>
                  </FlexBorderText>
                  <FlexBorderText>
                    <SectionName>{t('champions')}</SectionName>
                    <span>
                      {challenge?.requirements?.conditions?.[1]?.operator
                        ? t('blocked')
                        : t('allowed')}
                    </span>
                  </FlexBorderText>

                  <ChampsContainer>
                    {challengeChamps?.map((champ) => (
                      <Champ
                        img={champ.img}
                        name={champ.name}
                        key={champ.name}
                      />
                    ))}
                  </ChampsContainer>
                </TabContainer>
              </Tab>
              <Tab title={t('rules')}>
                <TabContainer>
                  <SectionName>{t('prizes')}</SectionName>
                  <PrizesContainer>
                    {challenge.prizes?.map((prize, i) => (
                      <ItemList key={i}>
                        <ChallengePrize>
                          {renderBadge(i + 1)} {i + 1}º
                        </ChallengePrize>
                        <ChallengeText>{`${prize.value} ${prize.type}`}</ChallengeText>
                      </ItemList>
                    ))}
                  </PrizesContainer>
                  <SectionName>{t('rule')}</SectionName>
                  <ul>
                    {challenge.rules?.map((rule, i) => (
                      <li key={i}>
                        <ChallengeText style={{ margin: '10px 0' }}>
                          {i + 1}. {rule}
                        </ChallengeText>
                      </li>
                    ))}
                  </ul>
                </TabContainer>
              </Tab>
            </TabView>
          </WrapperDetail>
          <WrapperDetail>
            {Platform.OS === 'web' ? (
              <AdsBanner inArticle marginBottom={20} />
            ) : null}
            <ContainerFlex>
              {challenge.active && leaderboard.lastUpdate ? (
                <>
                  <SectionName>{t('leaderboard')}</SectionName>
                  <LeaderboardUpdated>
                    {t('lastUpdate')}{' '}
                    {formatDistance(
                      leaderboard.lastUpdate,
                      new Date(),
                      renderLocale()
                    )}
                  </LeaderboardUpdated>
                </>
              ) : null}
            </ContainerFlex>
            {leaderboard.loading ? (
              <Loading />
            ) : (
              <LeaderBoardList
                data={leaderboard?.leaderboard}
                challenge={challenge}
                userName={name}
              />
            )}
            <br />
            {renderJoin()}
          </WrapperDetail>
        </WrapperDetails>
      )}
    </Layout>
  );
};

const ChallengePrize = styled.div`
  display: flex;
  color: #fff;
  font-size: 1.3rem;
  align-items: center;
`;

const WrapperDetails = styled.div`
  width: 100%;
  padding: 3rem 8rem;
  display: grid;
  grid-template-columns: repeat(2, minmax(500px, 600px));
  grid-gap: 20px;
  justify-content: space-between;
`;

const WrapperDetail = styled.div`
  width: 100%;
`;

const WrapperImg = styled.div`
  width: 100%;
  height: 250px;
  position: relative;
`;

const ChallengeImg = styled.img`
  width: 100%;
  height: 250px;
  object-fit: cover;
  object-position: center;
`;

const BadgePrize = styled.div`
  width: 20px;
  height: 25px;
  background: ${(props) => `url(${props.badge})`};
  background-size: cover;
  background-position: center;
  margin-right: 1rem;
`;

const ChallengeImgInfo = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  width: 100%;
  background: #0f1425a5;
  color: #fff;
  padding: 0 1rem;
  font-size: 1.1rem;
`;

const FlexBorderText = styled.div`
  width: 100%;
  color: #fff;
  font-size: 1.3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
  border-bottom: 1px solid #192038;
`;

const ChallengeName = styled.h1`
  font-size: 1.3rem;
  font-weight: bold;
  text-transform: uppercase;
  color: #fff;
`;

const TabContainer = styled.div`
  padding: 1rem;
  margin-top: 1rem;
`;

const ChallengeText = styled.div`
  color: #fff;
  text-align: left;
  font-size: 1.3rem;
`;

const DateContainer = styled.div`
  display: flex;
  margin: 2rem 0;
  > div {
    width: 50%;
  }
`;

export const VideoContainers = styled.div`
  display: flex;
  width: 100%;
  max-width: 580px;
  overflow-x: auto;
  height: 150px;
  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  ::-webkit-scrollbar-track {
    background: #f5f5f5;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #ccc;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #999;
  }
`;

export const Video = styled.iframe`
  width: 100%;
  max-width: 270px;
  border: 0;
  border-radius: 5px;
  height: 120px;
  margin-right: 1rem;
`;

const PrizesContainer = styled.div`
  width: 100%;
  height: 200px;
  overflow-y: auto;
  padding: 1rem;
  background: #141a2d;
  border: 1px solid #27314d;
`;

const ItemList = styled.div`
  width: 100%;
  display: flex;
  padding: 2rem 1rem;
  color: #fff;
  font-size: 1.3rem;
  justify-content: space-between;
  align-items: center;
`;

const LeaderboardUpdated = styled.p`
  width: 100%;
  max-width: 400px;
  font-size: 13px;
  text-align: right;
  color: #fff;
`;

const ContainerFlex = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ChampsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

export default ChallengeDetails;
